import React, { Component } from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Localization from '../../assets/localization';
import './meta-copy-button.sass';
import { BUTTON_DESIGNS } from '../../consts/consts';
import MetaButton from '../meta-button';
import MetaToolTip from '../meta-tooltip';

class MetaCopyButton extends Component {
  constructor(props) {
    super(props);
    this.mounted = false;
    this.state = {
      tooltipShown: false,
    };
  }

  componentDidMount() {
    this.mounted = true;
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  onCopied = () => {
    this.setState({ tooltipShown: true });
    setTimeout(() => {
      if (this.mounted) {
        this.setState({ tooltipShown: false });
      }
    }, 3000);
  };

  render() {
    return (
      <CopyToClipboard text={this.props.textToCopy}>
        <div className={classNames('copy-button', { 'no-border': !this.props.border, lean: this.props.isLean })}>
          {
            !this.props.isLean ? (
              <MetaButton
                disabled={this.props.disabled}
                title={
                  this.state.tooltipShown
                    ? (this.props.copiedTooltipTitle || Localization.COPIED_TOOLTIP)
                    : this.props.title
                }
                onClick={this.onCopied}
                icon="icon-copy"
                variant={this.props.border ? BUTTON_DESIGNS.SECONDARY : BUTTON_DESIGNS.TEXT_ONLY}
                text={this.props.buttonText}
              />
            ) : (
              <MetaToolTip text={this.state.tooltipShown
                ? (this.props.copiedTooltipTitle || Localization.COPIED_TOOLTIP)
                : this.props.title}
              >
                <div
                  disabled={this.props.disabled}
                  role="button"
                  onClick={this.onCopied}
                  className="icon-copy"
                />
              </MetaToolTip>

            )
          }
        </div>
      </CopyToClipboard>
    );
  }
}

MetaCopyButton.propTypes = {
  textToCopy: PropTypes.string,
};

MetaCopyButton.defaultProps = {
  textToCopy: '',
};

export default MetaCopyButton;
