import React from 'react';
import classNames from 'classnames';
import { COLLECTION_VIEW_TYPES } from '../../consts/consts';
import gridIcon from '../../images/grid-view.svg';
import listIcon from '../../images/list-view.svg';
import './meta-apps-view-switcher.sass';

const MetaAppsViewSwitcher = props => {
  const { currentView, onViewToggle } = props;
  return (
    <div className="meta-view-switcher">
      <span className="switch-title">{props.title}</span>
      <span
        className={classNames('switch-icon list-view-icon', { selected: currentView === COLLECTION_VIEW_TYPES.LIST_VIEW })}
        tabIndex={0}
        role="button"
        onClick={() => {
          onViewToggle(COLLECTION_VIEW_TYPES.LIST_VIEW);
        }}
      >
        <img src={listIcon} alt="list-view" />
      </span>
      <span
        className={classNames('switch-icon grid-view-icon', { selected: currentView === COLLECTION_VIEW_TYPES.TILES })}
        tabIndex={0}
        role="button"
        onClick={() => {
          onViewToggle(COLLECTION_VIEW_TYPES.TILES);
        }}
      >
        <img src={gridIcon} alt="grid-view" />
      </span>
    </div>
  );
};

export default MetaAppsViewSwitcher;
