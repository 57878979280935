import React from 'react';
import Localization from '../../assets/localization';
import MetaPopover from '../meta-popover';
import './diagnostic-mode-menu.sass';

const DiagnosticModeMenu = props => (
  <MetaPopover
    iconClass="icon-action-menu"
    containerClass="diagnostic-menu-popover"
    contentComponent={(
      <a
        href={props.diagnosticLink}
        className="diagnostic-menu"
        target={props.openInNewTab ? '_blank' : ''}
        rel="noreferrer"
      >
        <span>{Localization.RUN_DIAGNOSTIC_MODE}</span>
      </a>
    )}
    position="bottom"
    useOnClick
  />
);

export default DiagnosticModeMenu;
