import { ACTION_NAMES, updateTokens } from 'actions/actions';

const updateApiTokens = store => next => (action) => {
  if (action && action.type === ACTION_NAMES.CHECK_TOKEN_SUCCESS) {
    store.dispatch(updateTokens(action.payload));
  }
  return next(action);
};

export default updateApiTokens;
