const easyLinksQuery = `query EasyLinksQuery {
  easyLinks(ssp: true) {
    id
    name
    description
    modifiedAt
    createdAt
    domainName
    protocol
    port
    icon
    accessFqdn
    accessType
    proxy {
      isolation
    }
  }
}`;

export default easyLinksQuery;
