import React from 'react';
import PropTypes from 'prop-types';
import './meta-apps-category-item.sass';
import classNames from 'classnames';
import DiagnosticModeMenu from '../diagnostic-mode-menu';
import MetaToolTip from '../meta-tooltip';

const MetaAppsCategoryItem = props => {
  const {
    id,
    label,
    item,
    updateFunction,
    openInNewWindow,
    ItemIconComponent,
    diagnosticMode,
    isSublabel,
  } = props;

  const itemProps = Object.fromEntries(item);

  return (
    <MetaToolTip text={itemProps.tooltip || itemProps.name}>
      <div className="category-item-container">
        <a
          className={classNames('category-item', { 'category-item-disabled': itemProps.isDisabled })}
          rel={`noreferrer${openInNewWindow ? ' noopener' : ''}`}
          href={itemProps.link}
          target={openInNewWindow ? '_blank' : ''}
          onClick={() => updateFunction(id)}
        >
          {diagnosticMode && !itemProps.isDisabled && (
            <DiagnosticModeMenu
              diagnosticLink={itemProps.diagnosticLink}
              item={item}
              openInNewTab={openInNewWindow}
            />
          )
          }
          {itemProps.statusIcon && (<div className="status-icon"><img src={itemProps.statusIcon} alt="" /></div>)}
          <div className="item-icon-container"><ItemIconComponent {...itemProps} /></div>
          <span className={classNames('category-item-label', { 'category-sublabel': isSublabel })}>
            {label}
            <div>{itemProps.sublabel}</div>
          </span>
        </a>
      </div>
    </MetaToolTip>
  );
};

MetaAppsCategoryItem.defaultProps = {
  label: '',
  updateFunction: () => {},
  openInNewWindow: true,
};

MetaAppsCategoryItem.propTypes = {
  label: PropTypes.string,
  id: PropTypes.string.isRequired,
  updateFunction: PropTypes.func,
  openInNewWindow: PropTypes.bool,
};

export default MetaAppsCategoryItem;
