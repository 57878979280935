import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Snackbar from '@material-ui/core/Snackbar';
import { bindActionCreators } from 'redux';
import { hideToast } from '~/redux/ui-reducer';

class Toast extends Component {
  render() {
    const { toastState, closeToast } = this.props;
    return (
      <Snackbar
        ContentProps={{
          'aria-describedby': 'message-id',
        }}
        autoHideDuration={3000}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={toastState.isToastOpen}
        onClose={closeToast}
        message={toastState.message}
      />
    );
  }
}

Toast.propTypes = {
  toastState: PropTypes.shape({
    isToastOpen: PropTypes.bool,
    message: PropTypes.string,
  }).isRequired,
};

function mapStateToProps(state) {
  return {
    toastState: state.UIState.toast,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ closeToast: hideToast }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(Toast);
