import React, { useState, useEffect } from 'react';
import noop from 'lodash/noop';
import ClassNames from 'classnames';
import Checkbox from '@proofpoint/fuse-ui/components/Checkbox';
import './meta-checkbox.sass';

const MetaCheckbox = props => {
  const { onChange = noop, automationID = 'meta-checkbox' } = props;
  const [isChecked, setIsChecked] = useState(!!props.checked);

  useEffect(() => {
    if (isChecked !== !!props.checked) {
      setIsChecked(!!props.checked);
    }
  }, [props.checked]);

  const onCheckboxChange = evt => {
    const checkboxChecked = evt?.type === 'change' ? evt.target.checked : !isChecked;
    setIsChecked(checkboxChecked);
    onChange(checkboxChecked);
  };

  return (
    <div
      className={ClassNames('meta-checkbox', props.className, props.size)}
      data-automation={`${automationID}${props.disabled ? '-disabled' : ''}`}
    >
      <Checkbox
        inputProps={{ ...props.input }}
        disabled={props.disabled}
        onChange={onCheckboxChange}
        checked={isChecked}
      />
      {props.label && (
        <span
          role="button"
          tabIndex="0"
          className={ClassNames('checkbox-label', { disabled: props.disabled })}
          title={props.title}
          onClick={onCheckboxChange}
        >
          {props.label}
        </span>
      )}
    </div>
  );
};

export default MetaCheckbox;
