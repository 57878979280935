import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { config, EXTERNAL_ROUTES } from 'common/config/config';
import RDPIcon from 'assets/images/rdp.svg';
import VNCIcon from 'assets/images/vnc.svg';
import SSHIcon from 'assets/images/ssh.svg';
import WebIcon from 'assets/images/web.svg';
import { PROTOCOL_TYPES, PROTOCOL_OPTIONS } from 'consts/general-consts';

import './easylink-icon.sass';

function getEasyLinkIconPath(protocol) {
  switch (protocol) {
    case PROTOCOL_TYPES.RDP:
      return RDPIcon;
    case PROTOCOL_TYPES.VNC:
      return VNCIcon;
    case PROTOCOL_TYPES.SSH:
      return SSHIcon;
    case PROTOCOL_TYPES.HTTP:
      return WebIcon;
    case PROTOCOL_TYPES.HTTPS:
      return WebIcon;
    case PROTOCOL_OPTIONS.WEB:
      return WebIcon;
    default:
      return '';
  }
}

function EasyLinkIcon(props) {
  const { protocol, className = '', icon } = props;
  const imgFileName = icon
    ? `${config.assetsBase}${EXTERNAL_ROUTES.S3_MC_ICONS}/${icon}`
    : getEasyLinkIconPath(protocol);
  return (
    <div className={classNames('easylink-icon', className)}>
      <div className="mc-icon">
        <img className="image" alt="" src={imgFileName} />
      </div>
    </div>
  );
}

EasyLinkIcon.defaultProps = {
  className: '',
};

EasyLinkIcon.propTypes = {
  protocol: PropTypes.string.isRequired,
  className: PropTypes.string,
};

export default EasyLinkIcon;
