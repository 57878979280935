import React, { useState, useEffect } from 'react';
import ReactSelect from 'react-select';
import AsyncSelect from 'react-select/async';
import ReactHtmlParse from 'react-html-parser';
import classNames from 'classnames';
import isEmpty from 'lodash/isEmpty';
import has from 'lodash/has';
import MetaInputLabel from '../meta-input-label';
import Localization from '../../assets/localization';
import './meta-select.sass';

/*
- props.value should be one of the following:
  'a' / ['a', 'b'] for isMulti,
  { value: 'a', label: 'a'} / [{ value: 'a', label: 'a'}, { value: 'b', label: 'b'}] for isMulti
- props.options should be one of the following:
   ['a','b'],
   [{value: 'a', label: 'a'}, {value: 'b', label: 'b'}],
   { a: {value: 'a', label: 'a'}, b: {value: 'a', label: 'a'}}
- props.enhancedData is a boolean for when onChange function is already
  adapted to the ReactSelect component
 */

const getOptions = options => {
  if (isEmpty(options)) {
    return [];
  }
  let optionsArray = Object.values(options);
  if (!has(optionsArray[0], 'value')) {
    optionsArray = optionsArray.map(item => ({
      label: item,
      value: item,
    }));
  }
  return optionsArray;
};

const MetaSelect = props => {
  const [showError, setShowError] = useState(false);
  const [selectOptions, setSelectOptions] = useState(getOptions(props.options));
  const [isFocused, setIsFocused] = useState(false);
  const {
    label,
    subtitle,
    value,
    placeholder,
    onChange,
    className,
    classNamePrefix,
    containerClass,
    isMandatory,
    isDisabled,
    isClearable,
    isMulti,
    bottomParagraph,
    isOptionDisabled,
    size,
    suffix,
    enhancedData,
    noOptionsMessage,
    isSearchable,
    styles,
    loadOptions,
    onInputChange,
    defaultOptions,
    automationID = 'meta-select',
    validationError,
  } = props;

  useEffect(() => {
    if (props.options && props.options !== selectOptions) {
      setSelectOptions(getOptions(props.options));
    }
  }, [props.options]);

  // clears the error for checkbox with select (device setting) and cross section dependencies
  useEffect(() => {
    if (!isMandatory) {
      setShowError(false);
    }
  }, [isMandatory]);

  useEffect(() => {
    if (!validationError) {
      setShowError(false);
    } else {
      setShowError(props.validateOnChange && props.validationError);
    }
  }, [validationError]);

  const getSelectedValue = () => {
    if (has(props.value?.[0], 'value') || has(props.value, 'value')) {
      return props.value;
    }
    if (props.isMulti) {
      return selectOptions.filter(
        option => props.value && props.value.includes(option.value),
      );
    }
    return selectOptions.find(option => option.value === props.value) || null;
  };

  const onSelectChange = option => {
    setShowError(false);
    setIsFocused(false);
    if (has(option, 'value') && !enhancedData) {
      return onChange(option.value);
    }
    return onChange(option);
  };

  const selectedValue = getSelectedValue();

  const onBlur = () => {
    const isErr = !!validationError || (isMandatory && (
      value === undefined
      || value === null
      || value === ''
      || (isMulti && !value?.length)
    ));
    setIsFocused(false);
    setShowError(isErr);
  };

  const selectProps = {
    className: classNames('react-select-container', className, { error: showError }),
    classNamePrefix: classNames('react-select', classNamePrefix),
    isMulti: isMulti || false,
    menuIsOpen: props.menuIsOpen,
    onBlur,
    onFocus: () => setShowError(false),
    isDisabled,
    isClearable,
    isOptionDisabled,
    noOptionsMessage,
    isSearchable,
  };

  return (
    <div
      onFocus={() => setIsFocused(true)}
      onBlur={() => setIsFocused(false)}
      className={classNames('meta-select', size, containerClass)}
    >
      {label && (
        <MetaInputLabel
          label={label}
          isMandatory={isMandatory}
          subtitle={props.labelSubtitle}
          hasData={!isEmpty(selectedValue)}
          labelHelpTooltip={props.labelHelpTooltip}
          isFocused={isFocused}
          isError={showError}
        />
      )}
      {subtitle && <div className="field-subtitle">{ReactHtmlParse(subtitle)}</div>}
      <div className="select-container" data-automation={automationID}>
        {loadOptions
          ? (
            <AsyncSelect
              placeholder={placeholder || Localization.META_SELECT.DEFAULT_SELECT_PLACEHOLDER}
              onInputChange={onInputChange}
              defaultOptions={defaultOptions}
              onChange={onChange}
              value={value}
              loadOptions={loadOptions}
              {...selectProps}
            />
          )
          : (
            <ReactSelect
              value={selectedValue}
              onChange={onSelectChange}
              options={selectOptions}
              filterOption={props.filterOption}
              placeholder={placeholder || Localization.META_SELECT.DEFAULT_SELECT_PLACEHOLDER}
              onInputChange={onInputChange}
              styles={styles}
              {...selectProps}
            />
          )
        }
        {suffix && (<div className="field-suffix">{suffix}</div>)}
      </div>
      {bottomParagraph && (
        <div className="bottom-paragraph">{ReactHtmlParse(bottomParagraph)}</div>
      )}
      {showError
        && (
          <div className={classNames('field-error', showError ? '' : 'hidden')}>
            {validationError || Localization.META_SELECT.DEFAULT_REQUIRED_FIELD_ERROR}
          </div>
        )
      }
    </div>
  );
};

export default MetaSelect;
