import React from 'react';
import './loading-spinner.sass';

const LoadingSpinner = () => (
  <div className="loading-spinner-wrapper">
    <div className="lds-ring">
      <div className="circle" />
      <div className="circle" />
      <div className="circle" />
      <div className="circle" />
    </div>
  </div>
);

export default LoadingSpinner;
