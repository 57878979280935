import { fromJS } from 'immutable';
import { config } from 'common/config/config';
import { routes } from 'common/consts/routes';
import {
  isProdSiteAndEnv,
  getCookie,
  saveCookie,
  removeCookie,
  redirectToLogin,
  goToRoute,
} from 'common/utils/utils';
import { ACTION_NAMES, getNewToken, getUserAndOrgInfo, loginWithCode, getMyEasyLinks, getIsolationURL } from '~/actions/actions';
import { NSOF_REFRESH_TOKEN, HOST_REFRESH_TOKEN } from '~/consts/general-consts';
import { getEasyLinkHref, generateMetaConnectDirectLink } from '~/utils/utils';
import { ROUTES } from '~/consts/routes';
import Localization from '~/assets/localization';

const pendo = window.pendo || {};

const updatePendo = (uid, oid, eoid, ztnaLicense, wsLicense, isOrgInternal) => {
  if (!uid || !oid || !eoid) {
    return;
  }
  if (pendo.isReady && pendo.isReady()) {
    pendo.updateOptions({
      visitor: {
        id: uid,
        oid,
        eoid,
      },

      account: {
        id: oid, // Using pendo for entire org
        oid,
        eoid,
        tenantType: isOrgInternal,
      },
    });
  } else if (pendo.initialize) {
    pendo.initialize({
      visitor: {
        id: uid, // Required if user is logged in
        oid,
        eoid,
        // email:        // Recommended if using Pendo Feedback, or NPS Email
        // full_name:    // Recommended if using Pendo Feedback
        // role:         // Optional

        // You can add any additional visitor level key-values here,
        // as long as it's not one of the above reserved names.
      },

      account: {
        id: oid, // Using pendo for entire org, Highly recommended
        oid,
        eoid,
        ztnaLicense,
        wsLicense,
        tenantType: isOrgInternal,
        // name:         // Optional
        // is_paying:    // Recommended if using Pendo Feedback
        // monthly_value:// Recommended if using Pendo Feedback
        // planLevel:    // Optional
        // planPrice:    // Optional
        // creationDate: // Optional

        // You can add any additional account level key-values here,
        // as long as it's not one of the above reserved names.
      },
    });
  }
};

export function loadUserFromToken(code = '', easylinkId = '', isSupportMode = false) {
  return (dispatch) => {
    const token = getCookie(NSOF_REFRESH_TOKEN);

    if ((!token || token === '') && !code) {
      redirectToLogin(routes.META_CONNECT, true);
      return;
    }

    const loginFunction = code ? loginWithCode : getNewToken;
    const loginToken = code || token;

    dispatch({ type: ACTION_NAMES.CHECK_TOKEN_START });
    loginFunction(loginToken)
      .then((response = {}) => {
        const formattedResponse = fromJS(response);
        const refreshToken = formattedResponse.getIn(['data', 'refresh_token']);
        const accessToken = formattedResponse.getIn(['data', 'access_token']);
        const payload = {
          accessToken,
          refreshToken,
        };
        dispatch({ type: ACTION_NAMES.CHECK_TOKEN_SUCCESS, payload });

        getUserAndOrgInfo().then((infoResponse) => {
          if (isProdSiteAndEnv(window.location.hostname, config.apiEnv)) {
            const { user, effective_org, isOrgInternal } = infoResponse?.data || {};
            const userId = user?.id;
            const orgId = user?.org_id;
            const eorgId = effective_org?.id;
            const ztnaLicense = effective_org?.ztna_license;
            const wsLicense = effective_org?.proxy_license;
            updatePendo(userId, orgId, eorgId, ztnaLicense, wsLicense, isOrgInternal);
          }
          dispatch({ type: ACTION_NAMES.GET_USER_INFO_SUCCESS, payload: infoResponse.data });
        });
        saveCookie(NSOF_REFRESH_TOKEN, refreshToken);
        saveCookie(`${HOST_REFRESH_TOKEN}${config.org_short_name}`, refreshToken, {
          domain: config.metaSite,
        });
        if (easylinkId) {
          getMyEasyLinks({ accessToken }).then(myEasyLinksResponse => {
            const myEasyLinksRawData = myEasyLinksResponse.data;
            const easyLinkRawData = myEasyLinksRawData.find(
              easylink => easylink.id === easylinkId,
            ) || {};
            const easyLinkParsedData = fromJS({
              id: easylinkId,
              accessType: easyLinkRawData.access_type,
              accessFqdn: easyLinkRawData.access_fqdn,
              isolation: !!easyLinkRawData.proxy?.isolation,
              redirect: true,
            });
            const directLinkWithoutID = generateMetaConnectDirectLink();
            if (easyLinkParsedData.get('isolation')) {
              getIsolationURL(easylinkId).then(url => {
                window.location.href = url?.data?.isolation_url;
              })
                .catch(err => {
                  let title = Localization.ERROR_ISOLATION_TITLE;
                  if (err?.response?.status === 403) {
                    title = Localization.NO_ACCESS_ISOLATION_TITLE;
                  }
                  goToRoute(ROUTES.ERROR_PAGE, {
                    title,
                    reason: err?.response?.data?.detail || '',
                  });
                });
            } else {
              window.location.href = getEasyLinkHref(
                directLinkWithoutID,
                easyLinkParsedData,
                isSupportMode,
              );
            }
          });
        } else {
          dispatch({ type: ACTION_NAMES.APP_LOAD_FINISHED });
        }
      })
      .catch((error = {}) => {
        dispatch({ type: ACTION_NAMES.CHECK_TOKEN_FAIL, payload: error });
        removeCookie(NSOF_REFRESH_TOKEN);
        saveCookie(`${HOST_REFRESH_TOKEN}${config.org_short_name}`, '', {
          domain: config.metaSite,
        });
        redirectToLogin(routes.META_CONNECT, true);
      });
  };
}

export default function AppReducer(state = { loading: true }, action = {}) {
  let error;
  switch (action.type) {
    case ACTION_NAMES.CHECK_TOKEN_START:
      return { ...state,
        ...state,
        status: 'storage',
        error: null,
        loading: true };
    case ACTION_NAMES.CHECK_TOKEN_SUCCESS:
      return { ...state,
        ...state,
        tokens: action.payload,
        status: 'authenticated',
        error: null,
        loading: true };
    case ACTION_NAMES.CHECK_TOKEN_FAIL:
      error = action.payload.data || { message: action.payload.message };
      return { ...state,
        ...state,
        myInfo: {},
        orgInfo: {},
        status: 'storage',
        error,
        loading: false };
    case ACTION_NAMES.GET_USER_INFO_SUCCESS:
      return { ...state,
        ...state,
        myInfo: action.payload.user,
        orgInfo: action.payload.effective_org,
        privileges: action.payload.privileges,
        featureFlags: action.payload.featureFlags,
        ztnaLicense: action.payload.ztnaLicense,
        wsLicense: action.payload.wsLicense,
        userLoaded: true };
    case ACTION_NAMES.APP_LOAD_FINISHED:
      return { ...state,
        ...state,
        loading: false };

    default:
      return { ...state };
  }
}
