module.exports = {
  SECTION_ERROR: {
    ERROR_COMPONENT_TITLE: 'Error(s) have occurred',
  },
  DEFAULT_BROWSE_BTN_TEXT: 'Browse',
  META_SELECT: {
    DEFAULT_SELECT_PLACEHOLDER: 'Select',
    DEFAULT_REQUIRED_FIELD_ERROR: 'Required',
  },
  META_HEADER: {
    ZTNA_HEADER_TITLE: 'Secure Access',
    WS_HEADER_TITLE: 'Web Security',
    HELP: {
      OPEN_TICKET: 'Open Ticket',
      ONLINE_DOCUMENTATION: 'Online Documentation',
      API_GUIDE: 'API Reference Guide',
      OVERLAY_BUTTON: 'Open/Close Zoomin',
    },
    USER: {
      LOGOUT: 'Logout',
    },
    OTHER_ORG_MESSAGE: 'You are managing organization ',
    APP_SWITCHER: {
      ZTNA: 'Secure Access',
      WS: 'Web Security',
      SSP: 'Self-Service Portal',
      MC: 'MetaConnect',
      SSO: 'SSO Applications',
      WEB_ISOLATION: 'Web Isolation',
    },
    MY_APPLICATIONS: 'My Applications',
  },
  META_TEXT_AREA: {
    TOTAL_ITEMS_TITLE: 'Total {0} items',
    TOTAL_ITEM_TITLE: 'Total {0} item',
    SEARCH_PLACEHOLDER: 'Search List',
    DUPLICATE_ERROR: 'Value already exist',
    LINES_LIMIT_EXCEEDED: 'You have exceeded the maximum allowed number of values',
    ERROR_LINE_TITLE: 'Validation Errors ({0})',
  },
  TIMEZONE_TOGGLE: {
    LABEL: 'Display Time As',
    LOCAL: 'Local Time',
    UTC: 'UTC Time',
  },
  DATE_RANGE_PICKER_FROM: 'From',
  TIME: 'Time',
  DATE_RANGE_PICKER_LABEL: 'Date',
  TIME_PICKER_FROM: 'Start Time',
  INVALID_RANGE_ERROR: 'Invalid Date Range',
  TIME_PICKER_DEFAULT_LABELS: {
    HOUR: 'Last Hour',
    FOUR_HOURS: 'Last 4 Hours',
    SIX_HOURS: 'Last 6 Hours',
    DAY: 'Last 24 Hours',
    THREE_DAYS: 'Last 3 Days',
    WEEK: 'Last 7 Days',
    TWO_WEEKS: 'Last 14 Days',
    MONTH: 'Last 30 Days',
    THREE_MONTH: 'Last 90 Days',
    CUSTOM: 'Custom',
    FIFTEEN_MINUTES: 'Last 15 minutes',
    HALF_AN_HOUR: 'Last 30 minutes',
  },
  CIDR_INPUT: {
    IP: {
      TITLE: 'IP Address',
      PLACEHOLDER: 'XXX.XXX.XXX.XXX',
      INVALID_IP: 'This field must be a valid IPv4 address format. e.g. 10.0.0.1',
    },
    MASK: {
      TITLE: 'Net Mask',
      PLACEHOLDER: 'XX',
      ERROR_MESSAGE: '0-32',
    },
    MAX_CIDRS_ERROR: 'Up to {0} addresses allowed',
    CIDR_EXIST_ERROR: 'Address already exists',
    INVALID_CIDR: 'Invalid CIDR format',
  },
  APPS_FILTER_BAR: {
    NEW_WINDOW_LABEL: 'Open links in a new tab',
    SEARCH_PLACEHOLDER: 'Search',
    VIEW_SWITCHER_TITLE: 'View',
    FILTER_BY_PLACEHOLDER: 'Filter by type',
  },
  MY_APPLICATIONS: 'My Applications',
  LAST_CONNECTIONS: {
    TITLE: 'Recently Used',
  },
  RUN_DIAGNOSTIC_MODE: 'Run Diagnostic Mode',
  NO_ITEMS: 'No items to display',
  AND_OPERATOR: 'AND',
  REPORT_DISPLAY_NAMES: {
    ZTNA_USERS: 'Secure Access Users',
    WS_USERS: 'Web Security Users',
    ZTNA_DEVICES: 'Secure Access Devices',
    WS_DEVICES: 'Web Security Devices',
    ACTIVE_USERS: 'Active Users',
    WS_USER_ACTIVITY: 'User Activity',
    MAPPED_RESOURCES: 'Secure Access Mapped Resources',
    TOP_SITES: 'Top Sites',
    CATEGORIES: 'Category Types',
    TOP_ACTIONS: 'Actions',
    DEST_COUNTRIES: 'Destination Countries',
    TOP_CLOUD_APPS: 'Top Cloud Apps',
    WEB_SECURITY: 'Web Security',
    PROXY_UNPROTECTED_DEVICES: 'Web Security Unprotected Devices',
  },
  COUNTRIES_LABEL: {
    AD: 'Andorra',
    AE: 'United Arab Emirates',
    AF: 'Afghanistan',
    AG: 'Antigua and Barbuda',
    AI: 'Anguilla',
    AL: 'Albania',
    AM: 'Armenia',
    AO: 'Angola',
    AQ: 'Antarctica',
    AR: 'Argentina',
    AS: 'American Samoa',
    AT: 'Austria',
    AU: 'Australia',
    AW: 'Aruba',
    AZ: 'Azerbaijan',
    BA: 'Bosnia and Herzegovina',
    BB: 'Barbados',
    BD: 'Bangladesh',
    BE: 'Belgium',
    BF: 'Burkina Faso',
    BG: 'Bulgaria',
    BH: 'Bahrain',
    BI: 'Burundi',
    BJ: 'Benin',
    BM: 'Bermuda',
    BN: 'Brunei',
    BO: 'Bolivia',
    BR: 'Brazil',
    BS: 'Bahamas',
    BT: 'Bhutan',
    BV: 'Bouvet Island',
    BW: 'Botswana',
    BY: 'Belarus',
    BZ: 'Belize',
    CA: 'Canada',
    CC: 'Cocos [Keeling] Islands',
    CD: 'Congo [DRC]',
    CF: 'Central African Republic',
    CG: 'Congo [Republic]',
    CH: 'Switzerland',
    CI: "Côte d'Ivoire",
    CK: 'Cook Islands',
    CL: 'Chile',
    CM: 'Cameroon',
    CN: 'China',
    CO: 'Colombia',
    CR: 'Costa Rica',
    CU: 'Cuba',
    CV: 'Cape Verde',
    CX: 'Christmas Island',
    CY: 'Cyprus',
    CZ: 'Czech Republic',
    DE: 'Germany',
    DJ: 'Djibouti',
    DK: 'Denmark',
    DM: 'Dominica',
    DO: 'Dominican Republic',
    DZ: 'Algeria',
    EC: 'Ecuador',
    EE: 'Estonia',
    EG: 'Egypt',
    EH: 'Western Sahara',
    ER: 'Eritrea',
    ES: 'Spain',
    ET: 'Ethiopia',
    FI: 'Finland',
    FJ: 'Fiji',
    FK: 'Falkland Islands [Islas Malvinas]',
    FM: 'Micronesia',
    FO: 'Faroe Islands',
    FR: 'France',
    GA: 'Gabon',
    GB: 'United Kingdom',
    GD: 'Grenada',
    GE: 'Georgia',
    GF: 'French Guiana',
    GG: 'Guernsey',
    GH: 'Ghana',
    GI: 'Gibraltar',
    GL: 'Greenland',
    GM: 'Gambia',
    GN: 'Guinea',
    GP: 'Guadeloupe',
    GQ: 'Equatorial Guinea',
    GR: 'Greece',
    GS: 'South Georgia and the South Sandwich Islands',
    GT: 'Guatemala',
    GU: 'Guam',
    GW: 'Guinea-Bissau',
    GY: 'Guyana',
    GZ: 'Gaza Strip',
    HK: 'Hong Kong',
    HM: 'Heard Island and McDonald Islands',
    HN: 'Honduras',
    HR: 'Croatia',
    HT: 'Haiti',
    HU: 'Hungary',
    ID: 'Indonesia',
    IE: 'Ireland',
    IL: 'Israel',
    IM: 'Isle of Man',
    IN: 'India',
    IO: 'British Indian Ocean Territory',
    IQ: 'Iraq',
    IR: 'Iran',
    IS: 'Iceland',
    IT: 'Italy',
    JE: 'Jersey',
    JM: 'Jamaica',
    JO: 'Jordan',
    JP: 'Japan',
    KE: 'Kenya',
    KG: 'Kyrgyzstan',
    KH: 'Cambodia',
    KI: 'Kiribati',
    KM: 'Comoros',
    KN: 'Saint Kitts and Nevis',
    KP: 'North Korea',
    KR: 'South Korea',
    KW: 'Kuwait',
    KY: 'Cayman Islands',
    KZ: 'Kazakhstan',
    LA: 'Laos',
    LB: 'Lebanon',
    LC: 'Saint Lucia',
    LI: 'Liechtenstein',
    LK: 'Sri Lanka',
    LR: 'Liberia',
    LS: 'Lesotho',
    LT: 'Lithuania',
    LU: 'Luxembourg',
    LV: 'Latvia',
    LY: 'Libya',
    MA: 'Morocco',
    MC: 'Monaco',
    MD: 'Moldova',
    ME: 'Montenegro',
    MG: 'Madagascar',
    MH: 'Marshall Islands',
    MK: 'Macedonia [FYROM]',
    ML: 'Mali',
    MM: 'Myanmar [Burma]',
    MN: 'Mongolia',
    MO: 'Macau',
    MP: 'Northern Mariana Islands',
    MQ: 'Martinique',
    MR: 'Mauritania',
    MS: 'Montserrat',
    MT: 'Malta',
    MU: 'Mauritius',
    MV: 'Maldives',
    MW: 'Malawi',
    MX: 'Mexico',
    MY: 'Malaysia',
    MZ: 'Mozambique',
    NA: 'Namibia',
    NC: 'New Caledonia',
    NE: 'Niger',
    NF: 'Norfolk Island',
    NG: 'Nigeria',
    NI: 'Nicaragua',
    NL: 'Netherlands',
    NO: 'Norway',
    NP: 'Nepal',
    NR: 'Nauru',
    NU: 'Niue',
    NZ: 'New Zealand',
    OM: 'Oman',
    PA: 'Panama',
    PE: 'Peru',
    PF: 'French Polynesia',
    PG: 'Papua New Guinea',
    PH: 'Philippines',
    PK: 'Pakistan',
    PL: 'Poland',
    PM: 'Saint Pierre and Miquelon',
    PR: 'Puerto Rico',
    PS: 'Palestinian Territories',
    PT: 'Portugal',
    PW: 'Palau',
    PY: 'Paraguay',
    QA: 'Qatar',
    RE: 'Réunion',
    RO: 'Romania',
    RS: 'Serbia',
    RU: 'Russia',
    RW: 'Rwanda',
    SA: 'Saudi Arabia',
    SB: 'Solomon Islands',
    SC: 'Seychelles',
    SD: 'Sudan',
    SE: 'Sweden',
    SG: 'Singapore',
    SH: 'Saint Helena',
    SI: 'Slovenia',
    SJ: 'Svalbard and Jan Mayen',
    SK: 'Slovakia',
    SL: 'Sierra Leone',
    SM: 'San Marino',
    SN: 'Senegal',
    SO: 'Somalia',
    SR: 'Suriname',
    ST: 'São Tomé and Príncipe',
    SV: 'El Salvador',
    SY: 'Syria',
    SZ: 'Swaziland',
    TC: 'Turks and Caicos Islands',
    TD: 'Chad',
    TF: 'French Southern Territories',
    TG: 'Togo',
    TH: 'Thailand',
    TJ: 'Tajikistan',
    TK: 'Tokelau',
    TL: 'Timor-Leste',
    TM: 'Turkmenistan',
    TN: 'Tunisia',
    TO: 'Tonga',
    TR: 'Turkey',
    TT: 'Trinidad and Tobago',
    TV: 'Tuvalu',
    TW: 'Taiwan',
    TZ: 'Tanzania',
    UA: 'Ukraine',
    UG: 'Uganda',
    UM: 'U.S. Minor Outlying Islands',
    US: 'United States',
    UY: 'Uruguay',
    UZ: 'Uzbekistan',
    VA: 'Vatican City',
    VC: 'Saint Vincent and the Grenadines',
    VE: 'Venezuela',
    VG: 'British Virgin Islands',
    VI: 'U.S. Virgin Islands',
    VN: 'Vietnam',
    VU: 'Vanuatu',
    WF: 'Wallis and Futuna',
    WS: 'Samoa',
    XK: 'Kosovo',
    YE: 'Yemen',
    YT: 'Mayotte',
    ZA: 'South Africa',
    ZM: 'Zambia',
    ZW: 'Zimbabwe',
  },
  COPIED_TOOLTIP: 'Copied!',
};
