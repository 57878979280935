import Localization from '../assets/localization';
import { Links } from '../config/config';
import { redirectToRoute, getAppRouteForEnv } from '../utils/utils';

export const routes = {
  META_CONNECT: '/mc/',
  APPS: '/apps/',
  SSP: '/ssp/',
  ZTNA: '/ztna/',
  WS: '/ws/',
};

export const UI_APPS = {
  ZTNA: {
    name: 'ZTNA',
    id: 'ztnaApp',
    icon: { name: 'vpn_lock', kind: 'font:material' },
    label: Localization.META_HEADER.APP_SWITCHER.ZTNA,
    getLink: params => getAppRouteForEnv(
      routes.ZTNA, params,
      { defaultPageRoute: '#/dashboard' }
    ),
  },
  WS: {
    name: 'WS',
    id: 'webSecurityApp',
    icon: { name: 'security', kind: 'font:material' },
    label: Localization.META_HEADER.APP_SWITCHER.WS,
    getLink: params => getAppRouteForEnv(
      routes.WS,
      params,
      { defaultPageRoute: '#/dashboard' }
    ),
  },
  SSP: {
    name: 'SSP',
    icon: 'icon-apps-ssp',
    label: Localization.META_HEADER.APP_SWITCHER.SSP,
    getLink: () => getAppRouteForEnv(routes.SSP),
  },
  MC: {
    name: 'MC',
    icon: 'icon-apps-metaconnect',
    label: Localization.META_HEADER.APP_SWITCHER.MC,
    getLink: () => getAppRouteForEnv(routes.META_CONNECT),
  },
  MC_NO_ENV_SUFFIX: {
    name: 'MC_NO_ENV_SUFFIX',
    icon: 'icon-apps-metaconnect',
    label: Localization.META_HEADER.APP_SWITCHER.MC,
    getLink: () => getAppRouteForEnv(routes.META_CONNECT, null, { avoidEnvSuffix: true }),
  },
  SSO: {
    name: 'SSO',
    icon: 'icon-ic-network',
    label: Localization.META_HEADER.APP_SWITCHER.SSO,
    getLink: () => getAppRouteForEnv(routes.APPS),
  },
  ISOLATION: {
    name: 'ISOLATION',
    icon: 'icon-web-isolation',
    label: Localization.META_HEADER.APP_SWITCHER.WEB_ISOLATION,
    onClickFunc: () => redirectToRoute(Links.webIsolation),
  },
};
