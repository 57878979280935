import React from 'react';
import CategoryView from '../meta-apps-category-view';
import Spinner from '../meta-spinner';
import TableComponent from '../meta-apps-table-component';
import { COLLECTION_VIEW_TYPES } from '../../consts/consts';
import './meta-apps-view.sass';

function itemHasQuery(item, itemQuery, searchInFields = ['id', 'name', 'description']) {
  const lowerCasedQuery = itemQuery.toLowerCase();
  return searchInFields.find((field) => {
    const itemField = item.get(field) || '';
    return itemField.toLowerCase().includes(lowerCasedQuery);
  });
}

const MetaAppsView = props => {
  const {
    filterBar,
    loading,
    addLastConnection,
    columns,
    protocolOptions,
    diagnosticMode,
    collection,
    lastConnectionsCollection,
    ItemIconComponent,
    isSublabel,
  } = props;

  const filteredCollection = items => (filterBar.query || filterBar.queryType
    ? items
      .filter(item => (filterBar.query ? itemHasQuery(item, filterBar.query) : true))
      .filter(
        item => (filterBar.queryType && filterBar.queryType.length
          ? filterBar.queryType.includes(item.get('type').toLowerCase())
          : true),
      )
      .sort((a, b) => {
        if (a.get('lastAccess') && b.get('lastAccess')) {
          return b.get('lastAccess') - a.get('lastAccess');
        }
        return a.get('name').localeCompare(b.get('name'));
      })
    : items.sort((a, b) => {
      if (a.get('lastAccess') && b.get('lastAccess')) {
        return b.get('lastAccess') - a.get('lastAccess');
      }
      return a.get('name').localeCompare(b.get('name'));
    }));

  return (
    <div className="mc-home">
      <div className="mc-home-content">
        <Spinner spinning={loading}>
          <React.Fragment>
            {filterBar.currentView
              === COLLECTION_VIEW_TYPES.LIST_VIEW && (
              <div className="table-view-wrapper">
                <TableComponent
                  collection={filteredCollection(collection)}
                  columns={columns}
                />
              </div>
            )}
            {filterBar.currentView === COLLECTION_VIEW_TYPES.TILES && (
              <CategoryView
                categories={protocolOptions}
                collection={filteredCollection(collection)}
                updateFunction={addLastConnection}
                openInNewWindow={filterBar.openLinkInNewWindow}
                lastConnectionsCollection={filteredCollection(lastConnectionsCollection)}
                ItemIconComponent={ItemIconComponent}
                diagnosticMode={diagnosticMode}
                isSublabel={isSublabel}
              />
            )}
          </React.Fragment>
        </Spinner>
      </div>
    </div>
  );
};

export default MetaAppsView;
