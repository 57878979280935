import get from 'lodash/get';
import { fromJS } from 'immutable';
import { ACTION_NAMES } from '~/actions/actions';
import Localization from 'assets/localization';
import { hasFeatureAndFeatureFlag } from '../../../common/utils/utils';
import { generateMetaConnectDirectLink, getEasyLinkHref } from '../utils/utils';
import { PROTOCOL_TYPES } from '../../../mcdiag/src/consts/general-consts';
import isolationIcon from '../assets/images/isolation.png';
import { FEATURE_FLAGS } from '../../../common/consts/consts';

const initialState = {
  loading: false,
  error: null,
  data: {},
};

const getEasyLinksPayloadData = (payload, featureFlags) => {
  let data = { ...payload?.data };
  const easyLinks = get(data, 'data.easyLinks');
  if (easyLinks) {
    const directLinkWithoutID = generateMetaConnectDirectLink();
    const enhancedELs = easyLinks.map(easyLink => {
      const enhancedEl = easyLink;
      enhancedEl.link = getEasyLinkHref(directLinkWithoutID, fromJS(easyLink));
      enhancedEl.diagnosticLink = getEasyLinkHref(directLinkWithoutID, fromJS(easyLink), true);
      enhancedEl.type = easyLink.protocol === PROTOCOL_TYPES.HTTP
      || easyLink.protocol === PROTOCOL_TYPES.HTTPS
        ? 'web'
        : easyLink.protocol;
      if (easyLink.proxy?.isolation) {
        enhancedEl.sublabel = Localization.WEB_ISOLATION;
        enhancedEl.statusIcon = isolationIcon;
        enhancedEl.isDisabled = !hasFeatureAndFeatureFlag([FEATURE_FLAGS.ISOLATION], featureFlags);
        enhancedEl.tooltip = enhancedEl.isDisabled ? Localization.NO_ACCESS_ISOLATION_TITLE : '';
      }
      return enhancedEl;
    });
    data = {
      data: {
        ...data.data,
        easyLinks: enhancedELs,
        isSublabel: enhancedELs.some(enhancedEl => enhancedEl.sublabel),
      },
    };
  }
  return data;
};

export default function EntitiesReducer(state = initialState, action = {}) {
  let error;
  switch (action.type) {
    case ACTION_NAMES.GET_ENTITIES_START:
      return { ...state,
        ...state,
        loading: true };
    case ACTION_NAMES.GET_ENTITIES_SUCCESS:
      return { ...state,
        ...state,
        ...getEasyLinksPayloadData(action.payload, action.featureFlags),
        error: null,
        loading: false };
    case ACTION_NAMES.GET_ENTITIES_FAIL:
      error = action.payload.data || { message: action.payload.message };
      return { ...state,
        ...state,
        error,
        loading: false };
    case ACTION_NAMES.UPDATE_LAST_CONNECTIONS:
      return { ...state,
        ...state,
        ...action.payload };
    default:
      return { ...state };
  }
}
