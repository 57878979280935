import Localization from '../assets/localization';

export const NSOF_REFRESH_TOKEN = 'nsof-refreshToken';
export const HOST_REFRESH_TOKEN = '__Secure-metanetworks-login-';
export const REALM_COOKIE = 'realm';
export const PFPT_HOSTNAME = 'access.proofpoint';
export const PFPT_SUFFIX = 'com';
export const META_HOSTNAME = 'metanetworks';
export const PERSIST_LOGIN = 'persist';

export const REALM_TYPES_ENUM = {
  EU: 'eu',
  US: 'us',
};

// Fill with 'eu' or 'us'
// In case starting project without 'login', and REALM_COOKIE is missing
// Or to override the saved realm
export const DEFAULT_DEV_REALM = '';

export const RECENTLY_USED_APPS_AMOUNT = 6;
export const KEYSTROKE_KEYS = {
  UP: 38,
  DOWN: 40,
  ENTER: 13,
  ESC: 27,
  SPACE: 32,
};

export const EVENT_TYPES = {
  keyup: 'keyup',
  mousemove: 'mousemove',
  keydown: 'keydown',
  load: 'load',
  resize: 'resize',
};

export const BUTTON_DESIGNS = {
  PRIMARY: 'contained',
  SECONDARY: 'outlined',
  TEXT_ONLY: 'text',
};

export const BUTTON_STATUS = {
  ERROR: 'error',
  OK: 'ok',
};

export const META_COMPONENT_SIZES = {
  TINY: 'tiny',
  SMALL: 'small',
  MEDIUM: 'medium',
  LARGE: 'large',
};

export const MODAL_COMPONENT_SIZES = {
  XS: 'xs',
  SM: 'sm',
  MD: 'md',
  LG: 'lg',
  XL: 'xl',
};

export const COLLECTION_VIEW_TYPES = {
  LIST_VIEW: 'list',
  TILES: 'tiles',
};

export const FEATURE_FLAGS = {
  METACONNECT: 'metaconnect',
  EGRESS: 'egress_routes',
  ENTERPRISE_DNS: 'enterprise_dns',
  PEERING: 'peerings',
  META_IDP: 'apps',
  LDAP: 'ldap_prov_access_bridges',
  POSTURE_CHECK: 'posture_checks',
  LOGGING_INTERNET_TRAFFIC: 'log_internet_traffic',
  VERSION_CONTROL: 'version_controls',
  LOG_STREAMING: 'siem_access_bridges',
  PROXY_ACCESS: 'proxy_access',
  PROXY_PORT_RANGES: 'proxy_port_ranges',
  TRUSTED_NETWORKS: 'trusted_networks',
  POST_ACCESS_RULE: 'scan_rules',
  CONTENT_CATEGORIES: 'content_categories',
  THREAT_CATEGORIES: 'threat_categories',
  ATP: 'advanced_threat_protection',
  ISOLATION: 'isolation',
  METAPORT_IMAGE: 'metaport_images',
  SUBORGS: 'suborgs',
  SSL_BYPASS: 'ssl_bypass_rules',
  NOTIFICATIONS_CHANNELS: 'notification_channels',
  ALERTS: 'alerts',
  DEVICE_SETTINGS: 'device_settings',
  CASB_PROXY: 'casb_proxy',
  TENANT_RESTRICTIONS: 'tenant_restrictions',
  USER_SETTINGS: 'user_settings',
  ROLES: 'roles',
  ZTNA: 'ztna',
  SUPPORT: 'support',
  AAC_RULES: 'aac_rules',
  EXTENSION_SESSIONS: 'extension_sessions',
  LANDING_PAGES: 'landing_pages',
  AAC_ISOLATION_POLICIES: 'aac_isolation_policies',
  EXTENSION: 'extension',
  SAAS_ISOLATION: 'saas_isolation',
  IP_NETWORKS: 'ip_networks',
  TIME_FRAMES: 'time_frames',
  USER_CONTEXT_INTEGRATIONS: 'user_context_integrations',
  CERTIFICATES: 'certificates',
};

export const USER_PREFERENCES_KEY_CHARS_LIMIT = 60;

export const ENV_META_HOSTNAMES = {
  PROD: `nsof.${META_HOSTNAME}.com`,
  STG: `nsof.stage.${META_HOSTNAME}.com`,
  DEV: `nsof.dev.${META_HOSTNAME}.me`,
};

export const ENV_PFPT_HOSTNAMES = {
  PROD: `nsof.${PFPT_HOSTNAME}.com`,
  STG: `nsof.stage.${PFPT_HOSTNAME}.com`,
  DEV: `nsof.dev.${PFPT_HOSTNAME}.com`,
};

export const TIMEZONE_SETTINGS_ENUM = {
  local: {
    key: 'local',
    isLocal: true,
    label: Localization.TIMEZONE_TOGGLE.LOCAL,
  },
  utc: {
    key: 'utc',
    isUTC: true,
    label: Localization.TIMEZONE_TOGGLE.UTC,
  },
};

export const BUTTONS_ACTIONS = {
  CANCEL: 'cancel',
};
