import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { fromJS } from 'immutable';
import MetaAppsView from 'common/ui/meta-apps-view';
import DiagnosticModeMenu from 'common/ui/diagnostic-mode-menu';
import MetaToolTip from 'common/ui/meta-tooltip';
import { PROTOCOL_TYPES } from 'consts/general-consts';
import EasyLinkIcon from 'components/easylink-icon';
import Localization from 'assets/localization';
import {
  makeGqlQuery,
  updateLastConnections,
} from 'actions/actions';
import easyLinksQuery from './home-query';

const getColumns = (updateFunction, openInNewWindow) => fromJS([
  {
    name: 'Name',
    key: 'name',
    display: (_, parent = Map()) => (
      <MetaToolTip text={parent.get('tooltip') || parent.get('name')}>
        <div>
          <span className={parent.get('isDisabled') ? 'table-name-disabled' : ''}>
            <a
              className="easylink-name-column"
              href={parent.get('link')}
              onClick={() => updateFunction(parent.get('id'))}
              target={openInNewWindow ? '_blank' : ''}
              rel={`noreferrer${openInNewWindow ? ' noopener' : ''}`}
            >
              <EasyLinkIcon
                protocol={parent.get('protocol')}
                width={27}
                height={19}
                iconFileName={parent.get('icon')}
              />
              <span>{`${parent.get('name')} ${parent.get('proxy')?.get('isolation') ? Localization.WEB_ISOLATION : ''}`}</span>
            </a>
          </span>
        </div>
      </MetaToolTip>
    ),
  },
  {
    name: 'Protocol',
    key: 'protocol',
    display: (_, parent = Map()) => (
      <span>
        <span>{parent.get('protocol').toUpperCase()}</span>
      </span>
    ),
  },
  {
    name: 'Port',
    key: 'port',
  },
  {
    name: 'Description',
    key: 'description',
    display: (_, parent = Map()) => <span>{parent.get('description')}</span>,
  },
  {
    name: '',
    key: '',
    display: (_, parent = Map()) => !parent.get('isDisabled') && (
      <DiagnosticModeMenu
        diagnosticLink={parent.get('diagnosticLink')}
        item={parent}
        openInNewTab={openInNewWindow}
      />
    ),
  },
]);

const protocolOptions = Object.keys(PROTOCOL_TYPES).map(key => ({
  key,
  value: Localization.PROTOCOL_TYPES[key].LABEL,
}));

class MCHomeContainer extends Component {
  componentDidMount() {
    const { fetchData, fetchLastConnections } = this.props;
    fetchLastConnections();
    fetchData(easyLinksQuery);
  }

  render() {
    const {
      lastConnections = [],
      data = {},
      filterBar = {},
      loading,
      addLastConnection,
      isSupportMode = false,
    } = this.props;
    const { easyLinks = [], isSublabel } = data;
    const lastConnectionsCollection = () => {
      const enhancedLastConnection = [];
      lastConnections.forEach(lc => {
        const el = easyLinks.find(connection => connection.id === lc.id);
        if (el) {
          el.lastAccess = lc.lastAccess;
          enhancedLastConnection.push(el);
        }
      });
      return enhancedLastConnection;
    };

    const collection = items => fromJS(items);

    const columns = getColumns(
      addLastConnection,
      filterBar.openLinkInNewWindow,
      isSupportMode,
    );

    return (
      <MetaAppsView
        filterBar={filterBar}
        loading={loading}
        addLastConnection={addLastConnection}
        columns={columns}
        protocolOptions={protocolOptions}
        collection={collection(easyLinks)}
        lastConnectionsCollection={collection(lastConnectionsCollection())}
        ItemIconComponent={EasyLinkIcon}
        diagnosticMode
        isSublabel={isSublabel}
      />
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      fetchData: makeGqlQuery,
      addLastConnection: updateLastConnections,
      fetchLastConnections: updateLastConnections,
    },
    dispatch,
  );
}

export default connect(
  state => ({ ...state.Entities, ...state.UIState }),
  mapDispatchToProps,
)(MCHomeContainer);
