import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import Button from '@proofpoint/fuse-ui/components/Button';
import { BUTTON_DESIGNS } from '../../consts/consts';
import { executeOnEnterKey } from '../../utils/utils';
import MetaToolTip from '../meta-tooltip';
import './meta-button.sass';
import LoadingSpinner from '../loading-spinner';

const MetaButton = props => {
  const metaBtnClick = ev => !props.disabled && props.onClick(ev);
  const linkComponent = () => (
    <Link
      to={{
        pathname: typeof props.link === 'string' ? props.link : props.link?.pathname,
        search: typeof props.link === 'string' ? '' : props.link?.search,
      }}
    >
      {props.text && <span className="label">{props.text}</span>}
    </Link>
  );

  return (
    <MetaToolTip
      text={props.title && (!props.disabled || props.enableTooltip) ? props.title : null}
      placement={props.titlePlacement || 'top'}
      className={props.containerClassName}
    >
      <div
        data-automation={props.automationID || 'meta-button'}
      >
        <Button
          className={classNames(props.className, 'meta-button', !props.text ? 'icon-button' : '')}
          onClick={metaBtnClick}
          onKeyDown={e => executeOnEnterKey(e, metaBtnClick)}
          onMouseDown={props.onMouseDown}
          icon={props.icon ? (
            <div
              className={classNames(props.icon, 'meta-button-icon')}
            />
          ) : null}
          disabled={props.disabled}
          variant={props.variant}
          status={props.status}
          size={props.size}
          type={props.buttonType}
          buttonRef={props.buttonRef}
          target={props.target}
          href={props.externalLink}
        >
          {props.link ? linkComponent() : <span>{props.text}</span>}
          {props.loading && <LoadingSpinner /> }
        </Button>
      </div>

    </MetaToolTip>
  );
};

MetaButton.propTypes = {
  variant: PropTypes.string,
  buttonType: PropTypes.string,
  text: PropTypes.string,
  icon: PropTypes.string,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  title: PropTypes.string,
  titlePlacement: PropTypes.string,
};

MetaButton.defaultProps = {
  variant: BUTTON_DESIGNS.SECONDARY,
  buttonType: 'button',
  text: null,
  icon: null,
  disabled: false,
  title: null,
  titlePlacement: 'top',
  onClick: () => {},
};

export default MetaButton;
