import { combineReducers } from 'redux';
import { reducer as reduxFormReducer } from 'redux-form';

import AppState from 'scenes/app/app-state';
import Entities from './entities-reducer';
import UIState from './ui-reducer';

export default combineReducers({
  AppState,
  Entities,
  UIState,
  form: reduxFormReducer,
});
