module.exports = {
  APP_TITLE: 'MetaConnect',
  NO_MC_ERROR_TITLE: 'You are not authorized to access MetaConnect',
  ERROR_ISOLATION_TITLE: 'Error occurred while trying to access Isolation',
  NO_ACCESS_ISOLATION_TITLE: 'Missing Web Isolation license',
  NO_MC_ERROR: 'Please contact {0} system administrator to enable MetaConnect',
  PROTOCOL_TYPES: {
    RDP: {
      LABEL: 'RDP',
    },
    VNC: {
      LABEL: 'VNC',
    },
    SSH: {
      LABEL: 'SSH',
    },
    HTTP: {
      LABEL: 'HTTP',
    },
    HTTPS: {
      LABEL: 'HTTPS',
    },
  },
  WEB_ISOLATION: '(Web Isolation)',
};
