import React, { Fragment, useState, useEffect } from 'react';
import noop from 'lodash/noop';
import values from 'lodash/values';
import map from 'lodash/map';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import FontFaceObserver from 'fontfaceobserver';
import MetaToolTip from '../meta-tooltip';
import MetaHeaderIconMenu from './meta-header-icon-menu';
import { UI_APPS } from '../../consts/routes';
import Localization from '../../assets/localization';
import * as utils from '../../utils/utils';
import '../../assets/icons/icons.css';
import './meta-header.sass';
import TimezoneSelector from '../time-zone-selector/timezone-selector';
import ClassNames from 'classnames';

const MetaHeader = ({
  appName,
  appSwitcher,
  orgName,
  orgShortName,
  orgSwitcher,
  openOrgSwitcher,
  omniSearch,
  releaseNotes,
  help,
  userData,
  otherOrg,
  isPortal,
  orgId,
  currentApp = {},
  privateApps = [],
  globalAppSwitcher = [],
  appSwitcherCurrentApp,
}) => {
  const [isAppSwitcherOpen, setIsAppSwitcherOpen] = useState(false);
  const [whiteLabelLogo, setWhiteLabelLogo] = useState();
  const [appSwitcherSelectedApp, setAppSwitcherSelectedApp] = useState(appSwitcherCurrentApp);
  const [isFontLoaded, setIsFontLoaded] = useState(false);

  const getAppSwitcherSelectedApp = () => {
    if (currentApp.isWS) {
      setAppSwitcherSelectedApp(UI_APPS.WS);
    } else {
      setAppSwitcherSelectedApp(UI_APPS.ZTNA);
    }
  };

  const loadFont = () => {
    const fontClass = new FontFaceObserver('Material Icons Outlined');
    fontClass.load().then(() => {
      setIsFontLoaded(true);
    });
  };

  useEffect(() => {
    loadFont();
    if (!isPortal) {
      const logoPath = utils.getWhiteLabelAsset('logo');
      utils.checkImage(
        logoPath,
        () => setWhiteLabelLogo(logoPath),
        () => { },
      );
    } else {
      getAppSwitcherSelectedApp();
    }
  }, []);

  const changeAppSwitcher = () => {
    setIsAppSwitcherOpen(!isAppSwitcherOpen);
  };

  const onMyInfoClick = () => {
    if (userData.userCanSeeMyInfoPage) {
      userData.myInfoFunc(userData.userInfo.id);
    }
  };

  const userMenuItemsRender = () => {
    const { name, email } = userData.userInfo;
    const { myInfoFunc, logOutFunc, timeZone } = userData;
    return (
      <Fragment>
        <div
          className={classNames('user-menu-item', {
            'disable-hover': !userData.userCanSeeMyInfoPage,
          })}
          onClick={myInfoFunc && onMyInfoClick}
          onKeyDown={e => utils.executeOnEnterKey(e, onMyInfoClick)}
          role="button"
          tabIndex={0}
        >
          <span className="user-name">{name}</span>
          <span className="user-email">{email}</span>
        </div>
        {privateApps && !!privateApps.length && !currentApp.isPacfileSimulator && (
          <Fragment>
            <div className="user-menu-line" />
            <div className="private-apps-link">
              <div className="title">{Localization.META_HEADER.MY_APPLICATIONS}</div>
              {privateApps.map(item => (
                <a
                  className="app-link"
                  key={item.name}
                  href={item.getLink()}
                  target="_blank"
                  rel="noreferrer"
                >
                  <div className="label">
                    {item.label}
                  </div>
                  <div className="icon-external-link" />
                </a>
              ))}
            </div>
          </Fragment>
        )}
        {timeZone
          && !currentApp.isPacfileSimulator && <TimezoneSelector {...timeZone} />
        }
        <div className="user-menu-line" />
        <div
          className="user-menu-item"
          onClick={logOutFunc || noop}
          onKeyDown={e => utils.executeOnEnterKey(e, logOutFunc)}
          role="button"
          tabIndex={0}
        >
          {Localization.META_HEADER.USER.LOGOUT}
        </div>
      </Fragment>
    );
  };

  const appMenuItem = ({ label, selectedApp, icon, image, getLink }) => (
    <a
      key={label}
      className={classNames('apps-menu-item', { 'selected-app': selectedApp })}
      href={getLink(otherOrg ? { eorg: orgShortName } : null)}
    >
      {icon && !icon.kind && <span className={classNames(`apps-menu-item-icon ${icon}`)} />}
      {image && !icon && <img className="apps-menu-item-svg" src={image} alt={image} />}
      {icon?.kind
        && (
          <div className={classNames('apps-menu-item-icon', { 'material-font': icon.kind, loaded: isFontLoaded })}>
            {icon.name}
          </div>
        )
      }
      <span className="apps-menu-item-label">{label}</span>
    </a>
  );

  const renderAppMenuItems = () => (
    <div className="open-apps-menu-items">
      {map(values(UI_APPS), item => {
        const { name } = item;
        if (appSwitcher.includes(name)) {
          const selectedApp = appSwitcherSelectedApp?.name === name;
          return appMenuItem({ ...item, selectedApp });
        }
        return null;
      })}
      {globalAppSwitcher.map(item => {
        const { icon, name, getLink, id } = item;
        const selectedApp = appSwitcherSelectedApp?.name === name
          || appSwitcherSelectedApp?.id === id;
        return appMenuItem({ icon, label: name, getLink, selectedApp });
      })}
    </div>
  );

  const renderHeaderTitle = () => {
    if (currentApp.isZTNA && currentApp.isWS) {
      return null;
    }
    if (currentApp.isWS) {
      return <span className="app-title">{Localization.META_HEADER.WS_HEADER_TITLE}</span>;
    }
    if (currentApp.isZTNA) {
      return <span className="app-title">{Localization.META_HEADER.ZTNA_HEADER_TITLE}</span>;
    }
    if (currentApp.isPacfileSimulator) {
      return <span className="app-title">{Localization.META_HEADER.WS_HEADER_TITLE}</span>;
    }
    return null;
  };

  return (
    <div className="meta-header">
      <div className={classNames('unselectable header-container', { 'other-org': otherOrg })}>
        <div className="header-left-section">
          {!currentApp.isPacfileSimulator && <div className={classNames('apps-menu-btn', { 'other-org-btn': otherOrg })}>
            <span
              tabIndex={0}
              role="button"
              className="apps-icon icon-apps"
              onClick={changeAppSwitcher}
              onKeyDown={e => utils.executeOnEnterKey(e, changeAppSwitcher)}
            />
            {isAppSwitcherOpen && (
              <div className="app-switcher">
                <div className="open-apps-menu-container">
                  <div className="open-apps-menu-top">
                    <div className="open-apps-menu-btn">
                      <span
                        className="open-apps-menu-icon icon-apps"
                        onClick={changeAppSwitcher}
                        onKeyDown={e => utils.executeOnEnterKey(e, changeAppSwitcher)}
                        role="button"
                        tabIndex={0}
                      />
                    </div>
                    <span
                      className="open-apps-menu-proofpoint-logo icon-proofpoint-logo"
                    />
                  </div>
                  <div className="apps-title">Apps</div>
                  {renderAppMenuItems()}
                </div>
                <div
                  className="shadow focus-none"
                  role="button"
                  tabIndex={0}
                  onClick={() => setIsAppSwitcherOpen(false)}
                />
              </div>
            )}
          </div>}
          {whiteLabelLogo && !isPortal ? (
            <img alt="" className="white-label-logo" src={whiteLabelLogo} />
          ) : (
            <Fragment>
              <span
                className="proofpoint-logo icon-proofpoint-logo"
              />
              {renderHeaderTitle()}
            </Fragment>
          )}
          {appName && <span className="app-title">{appName}</span>}
        </div>
        {otherOrg && (
          <MetaToolTip text={orgId} placement="bottom-end">
            <div className="other-org-message">
              {Localization.META_HEADER.OTHER_ORG_MESSAGE + orgName}
            </div>
          </MetaToolTip>
        )}
        <div className="header-right-section">
          {!currentApp.isPacfileSimulator && <div data-automation="open-omni-search-widget" className="temp-omni">{omniSearch()}</div>}
          {/* id used for ClickOutside in the sub-org-tree */}
          <div
            role="button"
            className={classNames('org-container', { 'org-container-hover': orgSwitcher })}
            onClick={openOrgSwitcher}
            id="org-switcher-container"
            tabIndex={0}
          >
            {orgName && <div className="org-name">{orgName}</div>}
            {orgSwitcher && (
              <div className="org-switcher-wrapper">
                <span className="org-switcher icon-topbar-arrow-dropdown" />
              </div>
            )}
          </div>
          {releaseNotes && (
            <div className="icon-menu-spacing">
              <MetaHeaderIconMenu icon="icon-topbar-updates" otherOrg={otherOrg} />
            </div>
          )}
          {isPortal && (
            <div className="icon-menu-spacing">
              <MetaHeaderIconMenu icon="icon-ic-announce" noFocus noHover />
            </div>
          )}
          {help && help.length > 0 && (
            <div className="icon-menu-spacing">
              <MetaHeaderIconMenu
                className="icon-menu-spacing"
                icon="icon-help"
                menuItems={help}
                otherOrg={otherOrg}
              />
            </div>
          )}
          <div className="icon-menu-spacing">
            <MetaHeaderIconMenu
              menuItemsWithRender={userMenuItemsRender}
              otherOrg={otherOrg}
              text={utils.displayUserName(userData.userInfo.name)}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

MetaHeader.propTypes = {
  appName: PropTypes.string,
  appSwitcher: PropTypes.arrayOf(PropTypes.string).isRequired,
  orgName: PropTypes.string,
  orgSwitcher: PropTypes.bool,
  openOrgSwitcher: PropTypes.func,
  omniSearch: PropTypes.func,
  releaseNotes: PropTypes.bool,
  help: PropTypes.arrayOf(PropTypes.object.isRequired),
  userData: PropTypes.shape({
    id: PropTypes.string,
    userInfo: PropTypes.shape({
      name: PropTypes.string.isRequired,
      email: PropTypes.string.isRequired,
    }),
    logOutFunc: PropTypes.func.isRequired,
    myInfoFunc: PropTypes.func.isRequired,
    userCanSeeMyInfoPage: PropTypes.bool.isRequired,
  }).isRequired,
  otherOrg: PropTypes.bool,
  isPortal: PropTypes.bool,
  orgId: PropTypes.string,
};

MetaHeader.defaultProps = {
  appName: null,
  orgName: null,
  orgSwitcher: false,
  openOrgSwitcher: () => { },
  omniSearch: () => { },
  releaseNotes: false,
  help: null,
  otherOrg: false,
  isPortal: false,
  orgId: null,
};

export default MetaHeader;
