import { ACTION_NAMES, getEasyLinksDefaultView, getLinkOpenDefaultMode } from 'actions/actions';

const initialState = {
  filterBar: {
    currentView: getEasyLinksDefaultView(),
    openLinkInNewWindow: getLinkOpenDefaultMode(),
  },
  toast: {
    isToastOpen: false,
    message: '',
  },
};

export function hideToast() {
  return (dispatch) => {
    dispatch({ type: ACTION_NAMES.HIDE_TOAST });
  };
}

export default function UIReducer(state = initialState, action = {}) {
  switch (action.type) {
    case ACTION_NAMES.SHOW_TOAST:
      return { ...state,
        ...state,
        toast: {
          isToastOpen: true,
          message: action.payload.message,
        } };
    case ACTION_NAMES.HIDE_TOAST:
      return { ...state,
        ...state,
        toast: {
          isToastOpen: false,
          message: '',
        } };
    case ACTION_NAMES.CHANGE_CATEGORY_VIEW:
      return { ...state,
        ...state,
        filterBar: { ...state.filterBar, currentView: action.payload } };
    case ACTION_NAMES.CHANGE_LINK_OPEN_METHOD: {
      return { ...state,
        ...state,
        filterBar: { ...state.filterBar, openLinkInNewWindow: action.payload } };
    }
    case ACTION_NAMES.LIST_QUERY_SEARCH_CHANGE: {
      return { ...state,
        ...state,
        filterBar: { ...state.filterBar, query: action.payload } };
    }
    case ACTION_NAMES.LIST_QUERY_FILTER_CHANGE: {
      return { ...state,
        ...state,
        filterBar: { ...state.filterBar, queryType: action.payload } };
    }
    default:
      return { ...state };
  }
}
