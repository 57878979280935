import { fromJS } from 'immutable';

export const NSOF_REFRESH_TOKEN = 'nsof-refreshToken';
export const HOST_REFRESH_TOKEN = '__Secure-metanetworks-login-';
export const METACONNECT_REFRESH_TOKEN = '__Secure-metaconnect-';
export const PROTOCOL_TYPES = {
  RDP: 'rdp',
  VNC: 'vnc',
  HTTP: 'http',
  HTTPS: 'https',
  SSH: 'ssh',
};

export const PROTOCOL_OPTIONS = {
  RDP: 'rdp',
  VNC: 'vnc',
  WEB: 'web',
  SSH: 'ssh',
};

export const COLLECTION_VIEW_TYPES = fromJS({
  LIST_VIEW: 'list',
  TILES: 'tiles',
});

export default {
  NSOF_REFRESH_TOKEN,
  HOST_REFRESH_TOKEN,
  PROTOCOL_TYPES,
  PROTOCOL_OPTIONS,
  COLLECTION_VIEW_TYPES,
};
