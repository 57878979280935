import { UI_APPS } from '../../consts/routes';
import { FEATURE_FLAGS } from '../../consts/consts';
import { hasFeatureFlag } from '../../utils/utils';

export const getAppSwitcherItems = (
  featureFlags = [],
  privileges = [],
  ztnaLicense,
  wsLicense
) => {
  const switcherApps = [];
  if (privileges?.length > 0) {
    if (wsLicense) {
      switcherApps.push(UI_APPS.WS.name);
    }
    if (ztnaLicense) {
      switcherApps.push(UI_APPS.ZTNA.name);
    }
    if (!wsLicense && !ztnaLicense) {
      switcherApps.push(UI_APPS.WS.name);
    }
  }
  switcherApps.push(UI_APPS.SSP.name);
  if (hasFeatureFlag([FEATURE_FLAGS.METACONNECT], featureFlags)) {
    switcherApps.push(UI_APPS.MC.name);
  }
  if (hasFeatureFlag([FEATURE_FLAGS.META_IDP], featureFlags)) {
    switcherApps.push(UI_APPS.SSO.name);
  }
  return switcherApps;
};

export default { getAppSwitcherItems };
