import Cookies from 'js-cookie';
import { isEmpty } from 'lodash';
import { META_HOSTNAME, PFPT_HOSTNAME, REALM_COOKIE, DEFAULT_DEV_REALM, REALM_TYPES_ENUM } from '../consts/consts';

const BASE_SITE = '*BASE_SITE*';
const ENV_SUFFIX = '*ENV_SUFFIX*';
const API_PREFIX_ENUM = '*API_PREFIX_ENUM*';
const REALM_PREFIX_ENUM = '*REALM*';
const ENV_ENUM = '*ENV_ENUM*';
const PROD_ENDPOINT_SUFFIX = 'com';
const DEV_ENDPOINT_SUFFIX = 'me';

const isLocal = process.env.REACT_APP_ENV === 'local';
const isLocalGraphql = process.env.REACT_APP_ENV === 'graphql';

const configTemplate = {
  // TODO: change api.metanetworks.com - to support new proofpoint url
  baseServer: `https://*API_PREFIX_ENUM*api.*REALM*${META_HOSTNAME}.*ENV_ENUM*`,
  server: `https://*API_PREFIX_ENUM*api.*REALM*${META_HOSTNAME}.*ENV_ENUM*/v1`,
  samlServer: 'https://*API_PREFIX_ENUM*login.nsof.*ENV_ENUM*/v1',
  loginLinkTemplate: 'https://{0}{1}.*BASE_SITE*.*ENV_SUFFIX*/login/{2}?service={3}&next={4}{5}',
  sspLinkTemplate: 'https://{0}{1}.*BASE_SITE*.*ENV_SUFFIX*/ssp',
  clientsLinkTemplate: 'https://{0}{1}.*BASE_SITE*.*ENV_SUFFIX*/clients',
  metaConnectDirectLinkTemplate: 'https://*MC_ENV_SUFFIX*geo.*MC_GEO_PREFIX*mc.*BASE_SITE*.*ENV_SUFFIX*/login',
  metaConnectNativeDirectLinkTemplate:
    'https://{0}.native*MC_ENV_SUFFIX*.*MC_GEO_PREFIX*mc.*BASE_SITE*.*ENV_SUFFIX*/post_login',
  metaConnectLinkTemplate: 'https://{0}{1}.*BASE_SITE*.*ENV_SUFFIX*/mc',
  appsLinkTemplate: 'https://{0}{1}.*BASE_SITE*.*ENV_SUFFIX*/apps',
  appDirectLinkTemplate: 'https://{0}{1}.*BASE_SITE*.*ENV_SUFFIX*/login/apps/',
  metaSite: '*BASE_SITE*.*ENV_SUFFIX*',
  prodMetaSite: `*BASE_SITE*.${PROD_ENDPOINT_SUFFIX}`,
  assetsBase: 'https://assets.*BASE_SITE*.com',
  livetailService: `wss://*API_PREFIX_ENUM*livetail.*REALM*${META_HOSTNAME}.*ENV_ENUM*/`,
  clientsBase: 'https://clients.metanetworks.com', // TODO: move to use *BASE_SITE* once supported
};

export const config = {
  ...configTemplate,
  localServer: 'http://127.0.0.1:3030/v1',
  loginLinkLoginEndpoint: '',
  loginLinkLogoutEndpoint: 'logout',
};

const parseApiEnv = (
  includeProdPrefix = false,
  includeStagePrefix = true,
  isMetaConnect = false,
) => {
  const parsedHostname = window.location.hostname.split('.');
  if (isMetaConnect) {
    const envSuffix = parsedHostname.length > 3 ? `${parsedHostname[1]}` : '';
    const splitEnvSuffix = envSuffix.split('-')[0];
    if (splitEnvSuffix.startsWith('mc') && splitEnvSuffix !== 'mcp') {
      return `${splitEnvSuffix.slice(2)}-`;
    }
    return '';
  }
  const parseEnv = parsedHostname[0] ? parsedHostname[0].split('-') : '';
  // vpnVerification requires prod prefix although all the rest don't need it explicitly
  let apiPrefix = includeProdPrefix ? 'p-' : '';
  const strippedHostname = parsedHostname.length > 3 ? parsedHostname[1] : '';
  /**
   Dev and Staging environment should go to staging api by default
   unless explicitly written otherwise
  */
  if ((strippedHostname === 'stage' || parsedHostname[1] === 'localhost') && includeStagePrefix) {
    apiPrefix = 'stg1-';
  }
  if ((strippedHostname === 'dev' || strippedHostname === 'dev2' || strippedHostname === 'dev3' || strippedHostname === 'mui') && includeStagePrefix) {
    apiPrefix = 'dev-';
  }

  if (parseEnv.length > 1) {
    apiPrefix = `${parseEnv[0]}-`;
  }
  return apiPrefix;
};

const getApiEnvSuffix = (url = '', apiPrefix, options = {}) => {
  const cleanApiPrefix = apiPrefix && apiPrefix.length ? apiPrefix : 'p';
  const parsedApiPrefix = cleanApiPrefix === 'p' || cleanApiPrefix === 'stg1'
    ? options.prodSuffix || PROD_ENDPOINT_SUFFIX
    : DEV_ENDPOINT_SUFFIX;
  return url.replace(ENV_ENUM, parsedApiPrefix);
};

const getEnvSuffix = () => {
  const { hostname } = window.location;
  const parsedHostname = hostname ? hostname.split('.') : [];
  let envSuffix = parsedHostname.length > 3 ? `.${parsedHostname[1]}` : '';

  if (
    parsedHostname.length === 4
        && parsedHostname.includes(PFPT_HOSTNAME.split('.')[0])
        && parsedHostname.includes(PFPT_HOSTNAME.split('.')[1])
  ) {
    envSuffix = '';
  }

  if (parsedHostname[1] === 'localhost') {
    envSuffix = '.stage';
  }
  return envSuffix;
};

const getSiteName = () => {
  const { hostname } = window.location;
  const hostnameSplitted = hostname.split('.');
  if (hostnameSplitted[hostnameSplitted.length - 2] === PFPT_HOSTNAME.split('.')[1]) {
    return PFPT_HOSTNAME;
  }
  return META_HOSTNAME;
};

export const getBaseSite = url => {
  const baseSite = getSiteName();
  return url.replace(BASE_SITE, baseSite);
};

export const getSiteSuffix = url => {
  const env = getEnvSuffix();
  const baseSite = getSiteName();
  const envSuffix = env === '.dev' && baseSite === META_HOSTNAME ? 'me' : 'com';
  return url.replace(ENV_SUFFIX, envSuffix);
};

export const getSiteSuffixAndBase = url => {
  const urlWithSuffix = getSiteSuffix(url);
  return getBaseSite(urlWithSuffix);
};

const buildApiLink = (setting, realm, isMetaConnect = false, opts = {}) => {
  const apiPrefix = parseApiEnv(false, true, isMetaConnect);
  const cleanApiPrefix = apiPrefix.replace('-', '');
  const resolvedApiPrefix = opts.omitProdPrefix && cleanApiPrefix === 'p' ? '' : apiPrefix;
  const resolvedRealm = realm ? `${realm}.` : '';
  const resolvedSetting = getSiteSuffixAndBase(setting);
  return getApiEnvSuffix(resolvedSetting, cleanApiPrefix, opts)
    .replace(API_PREFIX_ENUM, resolvedApiPrefix)
    .replace(REALM_PREFIX_ENUM, resolvedRealm);
};

export const getHostname = () => {
  if (config?.metaSite) {
    try {
      return config.metaSite.split('.').slice(0, -1).join('.');
    } catch (error) {
      return null;
    }
  }
  return null;
};

export const getUrlParameter = name => {
  const nameParam = name.replace(/[[]/, '\\[').replace(/[\]]/, '\\]');
  const regex = new RegExp(`[\\?&]${nameParam}=([^&]*)`);
  const urlQuery = window.location.search;
  const results = regex.exec(urlQuery);
  return results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '));
};

export const EXTERNAL_ROUTES = {
  META_APPEARANCE_ASSETS: '/appearance/',
  S3_MC_ICONS: '/mc-icons',
  IDP_ICONS_PATH: '/idp-icons/',
  APP_ICON_ASSETS_PATH: '/apps/',
};

export const Links = {
  support: 'https://proofpointcommunities.force.com',
  documentation: `https://help.${PFPT_HOSTNAME}.com/knowledgebase/index.html`,
  wsDocumentation: `https://help.${PFPT_HOSTNAME}.com/knowledgebase/index_ws`,
  apiReferenceGuide: `https://help.${PFPT_HOSTNAME}.com/api/index.html`,
  webIsolation: 'https://proofpointisolation.com/console',
};

// checks if the url is meta connect / meta connect diag url
export const isMetaConnectByHostname = () => {
  const parsedHostname = window.location.hostname.split('.');
  return (parsedHostname.length === 5 && parsedHostname[2] === 'mc')
    || (parsedHostname.length === 4 && parsedHostname[1].startsWith('mc'));
};

const getSavedRealm = realm => {
  const realmCookie = Cookies.get(REALM_COOKIE);
  let savedRealm = realm || realmCookie;
  if (!savedRealm) {
    savedRealm = REALM_TYPES_ENUM.EU;
    const env = parseApiEnv(true, true);
    if (env === 'p-') {
      savedRealm = REALM_TYPES_ENUM.US;
    }
  }
  if (!realmCookie) {
    const cookieOptions = {
      secure: process.env.NODE_ENV === 'production',
      sameSite: 'Lax',
    };
    Cookies.set(REALM_COOKIE, savedRealm, cookieOptions);
  }
  if (!isEmpty(DEFAULT_DEV_REALM)) {
    return DEFAULT_DEV_REALM;
  }
  return savedRealm;
};

export const initRealmConfig = (options = {}) => {
  const settings = configTemplate;
  const { isRunningLocalMonkey } = options;
  const isRunningLocalServer = isLocal || isRunningLocalMonkey;
  config.server = isRunningLocalServer ? 'http://127.0.0.1/v1' : buildApiLink(settings.server, null, options.isMetaConnect);
};

export const initConfig = (options = {}) => {
  const settings = configTemplate;
  const { isRunningLocalMonkey, realm } = options;
  const savedRealm = getSavedRealm(realm);
  const isRunningLocalServer = isLocal || isRunningLocalMonkey;
  config.realm = savedRealm;
  config.isRunningLocalServer = isRunningLocalServer;
  config.baseServer = isRunningLocalServer ? 'http://127.0.0.1' : buildApiLink(settings.baseServer, savedRealm);
  config.graphqlServer = isLocalGraphql ? 'http://localhost:3030' : config.baseServer;
  config.mcdiagServer = buildApiLink(settings.server, savedRealm, true);
  config.server = isRunningLocalServer ? 'http://127.0.0.1/v1' : buildApiLink(settings.server, savedRealm);
  config.samlServer = buildApiLink(settings.samlServer, savedRealm, false, {
    prodSuffix: 'io',
  });
  config.metaSite = getSiteSuffixAndBase(config.metaSite);
  config.clientsLinkTemplate = getSiteSuffixAndBase(config.clientsLinkTemplate);
  config.loginLinkTemplate = getSiteSuffixAndBase(settings.loginLinkTemplate);
  config.metaConnectLinkTemplate = getSiteSuffixAndBase(settings.metaConnectLinkTemplate);
  config.metaConnectDirectLinkTemplate = getBaseSite(
    settings.metaConnectDirectLinkTemplate
  );
  config.livetailService = buildApiLink(settings.livetailService, savedRealm, false,
    { omitProdPrefix: true });
  config.metaConnectNativeDirectLinkTemplate = getSiteSuffixAndBase(
    settings.metaConnectNativeDirectLinkTemplate,
  );
  config.appDirectLinkTemplate = getSiteSuffixAndBase(settings.appDirectLinkTemplate);
  config.sspLinkTemplate = getSiteSuffixAndBase(settings.sspLinkTemplate);
  config.appsLinkTemplate = getSiteSuffixAndBase(settings.appsLinkTemplate);
  config.debugOnScreen = getUrlParameter('debugOnScreen') || false;
  config.debugStore = getUrlParameter('debugStore') || false;

  config.apiEnv = parseApiEnv(true, true, isMetaConnectByHostname()).replace('-', '');
  config.mcNative = config.apiEnv === 'p' ? 'native' : `native-mc${config.apiEnv}`;

  config.prodMetaSite = getBaseSite(settings.prodMetaSite);
  config.assetsBase = getBaseSite(settings.assetsBase);
};

export const resetMetaSiteToTemplateConfig = () => {
  config.metaSite = configTemplate.metaSite;
};
