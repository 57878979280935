import queryString from 'query-string';
import Cookies from 'js-cookie';
import intersection from 'lodash/intersection';
import difference from 'lodash/difference';
import filter from 'lodash/filter';
import forEach from 'lodash/forEach';
import numeral from 'numeral';
import debounce from 'lodash/debounce';
import Localization from '../assets/localization';
import { EXTERNAL_ROUTES, config, getSiteSuffix } from '../config/config';
import { KEYSTROKE_KEYS, NSOF_REFRESH_TOKEN, HOST_REFRESH_TOKEN, PFPT_HOSTNAME, PFPT_SUFFIX, PERSIST_LOGIN } from '../consts/consts';

export const getUrlParameter = (name = '', isCheckOnHref) => {
  if (!name) {
    return '';
  }
  const nameParam = name.replace(/[[]/, '\\[').replace(/[\]]/, '\\]');
  const regex = new RegExp(`[\\?&]${nameParam}=([^&]*)`);
  const urlQuery = isCheckOnHref ? window.location.href : window.location.search;
  const results = regex.exec(urlQuery);
  return results === null
    ? ''
    : decodeURIComponent(results[1].replace(/\+/g, ' '));
};

export const isOrganizationInternal = (orgsData = {}) => !!orgsData.tags?.find(tag => tag?.name === 'internal');

export const parseBlacklistFromURL = () => {
  const blacklistString = getUrlParameter('featureFlagsBL', true);
  const blacklist = blacklistString?.split(',')?.map(item => item.trim());
  return blacklist;
};

export const blacklistFeatureFlags = (featureFlags, itemToRemove) => difference(
  featureFlags,
  itemToRemove,
);

// **portal url have hash ('#') so its search is always empty.
//   so we need to look for the parameter in the whole url.
export const getUrlParameterInPortal = name => getUrlParameter(name, true);

export const redirectToRoute = (url, openInNewTab) => {
  if (!url) {
    return;
  }
  if (openInNewTab) {
    const newTab = window.open(url, '_blank');
    if (newTab) {
      newTab.focus();
    }
  } else {
    window.location.replace(url);
  }
};

export const getEnvSuffix = () => {
  const { hostname } = window.location;
  const parsedHostname = hostname ? hostname.split('.') : [];
  let envSuffix = parsedHostname.length > 3 ? `.${parsedHostname[1]}` : '';

  if (
    parsedHostname.length === 4
        && parsedHostname.includes(PFPT_HOSTNAME.split('.')[0])
        && parsedHostname.includes(PFPT_HOSTNAME.split('.')[1])
  ) {
    envSuffix = '';
  }

  if (parsedHostname[1] === 'localhost') {
    envSuffix = '.stage';
  }

  if (parsedHostname[1] === 'mui') {
    return `.${parsedHostname[1]}.${parsedHostname[2]}`;
  }
  return envSuffix;
};

export const parseOrgName = (orgName) => {
  if (!orgName) {
    return null;
  }
  const parsedOrg = orgName.split('-');
  // this check is for mcdiag - the org section there contains 3 parts
  if (parsedOrg.length > 2) {
    return parsedOrg[1];
  }
  return parsedOrg[parsedOrg.length - 1];
};

export const updateOrgsInConfig = () => {
  const hostNames = window.location.hostname.split('.');
  let orgShortName = hostNames.length > 1 ? hostNames[0] : null;
  if (window.location.hostname === `${PFPT_HOSTNAME}.${PFPT_SUFFIX}`) {
    orgShortName = null;
  }
  const eOrgShortName = getUrlParameter('eorg', true) || orgShortName;
  config.org_short_name = parseOrgName(orgShortName);
  config.eorg_short_name = parseOrgName(eOrgShortName);
};

export const parseApiEnv = (
  includeProdPrefix = false,
  includeStagePrefix = true,
  isMetaConnect = false,
) => {
  const parsedHostname = window.location.hostname.split('.');
  if (isMetaConnect) {
    const envSuffix = parsedHostname.length > 3 ? `${parsedHostname[1]}` : '';
    const splitEnvSuffix = envSuffix.split('-')[0];
    switch (splitEnvSuffix) {
      case 'mcstg1':
        return 'stg1-';
      case 'mcdev':
        return 'dev-';
      default:
        return '';
    }
  }
  const parseEnv = parsedHostname[0] ? parsedHostname[0].split('-') : [];
  // vpnVerification requires prod prefix although all the rest don't need it explicitly
  let apiPrefix = includeProdPrefix ? 'p-' : '';
  const strippedHostname = parsedHostname.length > 3 ? parsedHostname[1] : '';
  /**
   Dev and Staging environment should go to staging api by default
   unless explicitly written otherwise
   */
  if ((strippedHostname === 'stage' || parsedHostname[1] === 'localhost') && includeStagePrefix) {
    apiPrefix = 'stg1-';
  }
  if ((strippedHostname === 'dev' || strippedHostname === 'dev2' || strippedHostname === 'dev3' || strippedHostname === 'mui') && includeStagePrefix) {
    apiPrefix = 'dev-';
  }

  if (parseEnv.length > 1) {
    apiPrefix = `${parseEnv[0]}-`;
  }
  return apiPrefix;
};

export const getAppRouteForEnv = (route = '', params, options = {}) => {
  const apiPrefix = parseApiEnv(false, false, options.avoidEnvSuffix);
  const envSuffix = options.avoidEnvSuffix ? '' : getEnvSuffix();
  updateOrgsInConfig();
  const parsedQuery = queryString.stringify(params);
  const url = `https://${apiPrefix}${config.org_short_name}${envSuffix}.${getSiteSuffix(config.metaSite)}`;

  let queryPrefix = '';
  if (parsedQuery.length) {
    queryPrefix = url.includes('?') ? '&' : '?';
  }
  const defaultPageRoute = options.defaultPageRoute || '';

  return `${url}${route}${defaultPageRoute}${queryPrefix}${parsedQuery}`;
};

export const goToRoute = (route, params, options = {}) => {
  redirectToRoute(getAppRouteForEnv(route, params, options), options.openInNewTab);
};

export const getWhiteLabelAsset = (fileName) => {
  const orgName = config.org_short_name;
  const apiEnv = parseApiEnv(true, true);
  const apiPrefixNoDash = apiEnv.replace('-', '');
  return `${config.assetsBase}${EXTERNAL_ROUTES.META_APPEARANCE_ASSETS}${apiPrefixNoDash}/${orgName}/${fileName}`;
};

export const checkImage = (imageSrc, good, bad) => {
  const img = new Image();
  img.onload = good;
  img.onerror = bad;
  img.src = imageSrc;
};

export const displayUserName = userName => userName
  .split(' ')
  .slice(0, 1)
  .map(str => str.substr(0, 1).toUpperCase())
  .toString()
  .replace(',', '') || ' ';

export const executeOnEnterKey = (event, func) => {
  if (func && event && event.keyCode === KEYSTROKE_KEYS.ENTER) {
    func();
  }
};

export function hasFeatureFlag(flagNames, sessionFeatureFlags, expectAll) {
  if (!flagNames?.length) {
    return false;
  }
  return expectAll
    ? intersection(flagNames, sessionFeatureFlags).length === flagNames.length
    : intersection(flagNames, sessionFeatureFlags).length > 0;
}
export function hasFeatureAndFeatureFlag(flagNames, sessionFeatureFlags) {
  if (!flagNames || !flagNames.length) {
    return true;
  }
  return hasFeatureFlag(flagNames, sessionFeatureFlags);
}

export function filterListByFeatureFlag(items = [], sessionFeatureFlags) {
  if (!sessionFeatureFlags?.length) {
    return [];
  }
  return filter(items, item => !difference(item.featureFlags, sessionFeatureFlags).length);
}

export const getFormattedNumber = (value, format = '0,0') => numeral(value).format(format);

export function checkIfStringArrayHasString(strArray = [], inputStr, caseSensitive = false) {
  if (!strArray && !strArray.length) {
    return false;
  }
  if (caseSensitive) {
    return strArray.filter((strItem) => strItem === inputStr).length > 0;
  }
  return strArray.filter((strItem) => strItem.toLowerCase() === inputStr.toLowerCase()).length > 0;
}

export const generateLoginLink = (
  next = null,
  withLogoutFlag = false,
  appPathName,
  addExtraQuery,
) => {
  const pathName = appPathName || window.location.pathname;
  const search = addExtraQuery ? window.location.search : '';
  const nextUrl = next || `${window.location.origin}${pathName}${search}`;
  const hostNames = window.location.hostname.split('.');
  const orgShortName = hostNames.length > 1 ? hostNames[0] : '';
  const endpoint = withLogoutFlag
    ? config.loginLinkLogoutEndpoint
    : config.loginLinkLoginEndpoint;
  const envSuffix = getEnvSuffix();
  let extraQuery = '';
  if (addExtraQuery) {
    const invokeLoginWithSSO = getUrlParameter('invokeLoginWithSSO');
    extraQuery = invokeLoginWithSSO && invokeLoginWithSSO !== 'false'
      ? `&invokeLoginWithSSO=${invokeLoginWithSSO}`
      : '';
  }
  if (config.isRunningLocalServer) {
    extraQuery += '&isRunningLocalMonkey=true';
  }
  return Localization.formatString(
    config.loginLinkTemplate,
    orgShortName,
    envSuffix,
    endpoint,
    pathName.slice(1),
    encodeURIComponent(nextUrl),
    extraQuery,
  );
};

export const getErrorMessage = error => {
  if (typeof error === 'string') {
    return error;
  }

  if (error && error.message) {
    return error.message;
  }

  return '';
};

export const getCookie = key => Cookies.get(key);

export const saveCookie = (key, value, options = {}) => {
  const cookieOptions = {
    secure: process.env.NODE_ENV === 'production',
    sameSite: 'Lax',
  };
  if (options.expirationInSeconds) {
    //  Turn seconds to day fragments 60 sec / 60 min / 24 hours
    cookieOptions.expires = options.expirationInSeconds / 60 / 60 / 24;
  }
  if (options.domain) {
    cookieOptions.domain = options.domain;
  }
  Cookies.set(key, value, cookieOptions);
};

export const removeCookie = (key, options = {}) => {
  Cookies.remove(key, options);
};

export const redirectToLogin = (appRoute, addExtraQuery, nextUrl = null, logoutFlag = false) => {
  window.location.href = generateLoginLink(nextUrl, logoutFlag, appRoute, addExtraQuery);
};

export const logoutUser = () => {
  removeCookie(NSOF_REFRESH_TOKEN);
  saveCookie(`${HOST_REFRESH_TOKEN}${config.org_short_name}`, '', {
    domain: config.metaSite,
  });
  removeCookie(PERSIST_LOGIN);
  const appRoute = window.location.pathname.split('/')[1];
  redirectToLogin(appRoute ? `/${appRoute}` : null, false, window.location.href, true);
};

export const isProdSiteAndEnv = (url, apiEnv) => {
  if (!url || typeof url !== 'string' || apiEnv !== 'p') {
    return false;
  }
  const splitUrl = url.split('.');
  return (splitUrl.length === 3 && `${splitUrl[1]}.${splitUrl[2]}` === config.prodMetaSite)
      || (splitUrl.length === 4 && (splitUrl.slice(1, -1).join('.') === PFPT_HOSTNAME));
};

export const removeParamFromQueryString = (query, paramsToRemove) => {
  if (!query || (typeof query !== 'string')) {
    return undefined;
  }
  try {
    const parsedQuery = queryString.parse(query);
    forEach(paramsToRemove, param => {
      if (parsedQuery[param]) {
        delete parsedQuery[param];
      }
    });
    return queryString.stringify(parsedQuery);
  } catch (error) {
    return undefined;
  }
};

export const removeCodeFromUrl = () => {
  const [link, queryParams] = window.location.href.split(/\?(.+)/);
  const queryWithoutCode = removeParamFromQueryString(queryParams, ['code']);
  const newQueryParams = queryWithoutCode ? `?${queryWithoutCode}` : '';
  const newUrl = `${link}${newQueryParams}`;
  window.history.pushState({}, null, newUrl);
};

export const replacePathname = (licensePathName, options) => {
  const { hash, origin, search } = window.location;
  const { pathName, considerQueryParams } = options || {};
  let path;
  if (pathName && considerQueryParams) {
    const queryParams = hash.split('?')?.[1];
    path = `#${pathName}${queryParams ? `?${queryParams}` : ''}`;
  } else {
    path = pathName ? `#${pathName}` : `${hash}${search}`;
  }
  const newUrl = `${origin}${licensePathName}${path}`;
  window.location.href = newUrl;
  return newUrl;
};

export const onDebounceInputChange = (onChange, period) => debounce(onChange, period);
