import React from 'react';
import map from 'lodash/map';
import MetaCheckbox from '../meta-checkbox';
import MetaSearchInput from '../meta-search-input';
import MultiSelectCheckbox from '../multi-select-checkbox';
import ViewSwitcher from '../meta-apps-view-switcher';
import Localization from '../../assets/localization';
import './meta-apps-filter-bar.sass';

const MetaAppsFilterBar = props => {
  const {
    onViewToggle,
    currentView,
    onOpenMethodToggle,
    openLinkInNewWindow,
    onFilterSearchChanged,
    onFilterFilteringChanged,
    protocolOptions,
    ProtocolIconComponent,
    selectValue,
  } = props;
  return (
    <div className="meta-apps-filter-bar">
      <div className="horizontal-section">
        <MetaSearchInput
          autoFocus
          handleChange={onFilterSearchChanged}
          placeholder={Localization.APPS_FILTER_BAR.SEARCH_PLACEHOLDER}
          size="medium"
        />
        {protocolOptions && ProtocolIconComponent
        && (
          <div className="multi-select-container">
            <MultiSelectCheckbox
              placeholder={Localization.APPS_FILTER_BAR.FILTER_BY_PLACEHOLDER}
              options={map(protocolOptions, option => ({
                value: option,
                label: (
                  <span>
                    <ProtocolIconComponent protocol={option} />
                    <span>{option.toUpperCase()}</span>
                  </span>
                ),
                key: option,
              }))}
              onChange={e => {
                onFilterFilteringChanged(e?.map(item => item.value));
              }}
              defaultValue=""
              selectValue={selectValue}
            />
          </div>
        )
        }
      </div>
      <div className="horizontal-section">
        <div className="link-open-method">
          <MetaCheckbox
            checked={openLinkInNewWindow}
            onChange={value => {
              onOpenMethodToggle(value);
            }}
            label={Localization.APPS_FILTER_BAR.NEW_WINDOW_LABEL}
          />
        </div>
        <ViewSwitcher
          onViewToggle={onViewToggle}
          currentView={currentView}
          title={Localization.APPS_FILTER_BAR.VIEW_SWITCHER_TITLE}
        />
      </div>
    </div>
  );
};

export default MetaAppsFilterBar;
