import { NSOF_REFRESH_TOKEN, REALM_COOKIE } from '../consts/consts';
import { logoutUser, getCookie } from '../utils/utils';

let logoutWatchInterval;

const stopLogoutWatchInterval = () => {
  clearInterval(logoutWatchInterval);
  logoutWatchInterval = null;
};

const logout = () => {
  stopLogoutWatchInterval();
  logoutUser();
};

const logoutWatch = logoutFunc => {
  const refreshToken = getCookie(NSOF_REFRESH_TOKEN);
  const realmCookie = getCookie(REALM_COOKIE);

  if (!refreshToken || !realmCookie) {
    logoutFunc();
  }
};

const startLogoutWatchInterval = logoutFunc => {
  if (!logoutWatchInterval) {
    logoutWatchInterval = setInterval(logoutWatch, 1000, logoutFunc);
  }
};

export {
  stopLogoutWatchInterval,
  logout,
  startLogoutWatchInterval,
};
