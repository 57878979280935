import { config, getHostname } from '../config/config';
import Localization from '../assets/localization';
import { getUrlParameter, parseApiEnv, parseOrgName } from './utils';
import { PFPT_HOSTNAME } from '../consts/consts';

const ENV_SUFFIX = '*ENV_SUFFIX*';
const MC_ENV_SUFFIX = '*MC_ENV_SUFFIX*';
const MC_GEO_PREFIX = '*MC_GEO_PREFIX*';

export const McAppsUtils = {
  getSiteSuffix: (url, apiPrefixAfter = true) => {
    const zoneParam = getUrlParameter('mczone');
    const apiPrefix = parseApiEnv(true, true);
    const isProofpointHost = getHostname() === PFPT_HOSTNAME;
    const apiPrefixWithoutDash = apiPrefix.replace('-', '');
    let uiSuffix = 'com';
    let mcEnv = `${apiPrefixAfter ? '' : '-'}mc${apiPrefixWithoutDash}${apiPrefixAfter ? '-' : ''}`;
    let geoPrefix = '';
    if (zoneParam && zoneParam.length) {
      if (zoneParam !== 'mcstg1' && zoneParam !== 'mcp') {
        uiSuffix = isProofpointHost ? 'com' : 'me';
        geoPrefix = isProofpointHost ? 'dev' : '';
      }
      mcEnv = `${apiPrefixAfter ? '-' : ''}${zoneParam}${apiPrefixAfter ? '-' : ''}`;
    } else {
      switch (apiPrefix) {
        case 'p-':
          mcEnv = '';
          break;
        case 'stg1-':
          break;
        default:
          uiSuffix = isProofpointHost ? 'com' : 'me';
          geoPrefix = isProofpointHost ? 'dev' : '';
          break;
      }
    }


    return url
      .replace(ENV_SUFFIX, uiSuffix)
      .replace(MC_GEO_PREFIX, geoPrefix)
      .replace(MC_ENV_SUFFIX, mcEnv);
  },

  generateNativeDirectLink: () => {
    const hostNames = window.location.hostname.split('.');
    const orgShortName = hostNames.length > 1 ? parseOrgName(hostNames[0]) : '';
    const directLink = McAppsUtils.getSiteSuffix(
      config.metaConnectNativeDirectLinkTemplate,
      false,
      config.prodMetaSite
    );
    return Localization.formatString(directLink, orgShortName);
  },
};

export default McAppsUtils;
