import React from 'react';
import classNames from 'classnames';
import MetaPopover from '../meta-popover';
import { META_COMPONENT_SIZES } from '../../consts/consts';
import './meta-input-label.sass';

const MetaInputLabel = props => (
  <div className="meta-input-label">
    <div className="label-container">
      <div className={classNames(props.isDisabled ? 'disabled-input-label-text' : 'input-label-text', {
        focused: props.isFocused,
        error: props.isError,
      })}
      >
        {props.label}
      </div>
      {props.isMandatory && (
        <div className="star">
          *
        </div>
      )}
      {props.labelLink && (
        <div className="label-link">
          <span> (</span>
          <a
            href={props.labelLink.link}
            target="_blank"
            rel="noreferrer"
            className="label-link"
          >
            {props.labelLink.title}
          </a>
          <span>)</span>
        </div>
      )}
      {props.labelHelpTooltip && (
        <div className="label-help-tooltip">
          <MetaPopover
            iconClass="icon-tooltip help-button"
            contentComponent={(
              <div>{props.labelHelpTooltip}</div>
            )}
            size={META_COMPONENT_SIZES.SMALL}
            multiLine={props.multiLinelabelHelpTooltip}
          />
        </div>
      )}
    </div>
    {props.subtitle && <div className="field-subtitle">{props.subtitle}</div> }
  </div>
);

export default MetaInputLabel;
