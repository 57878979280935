import React, { useState } from 'react';
import ClassNames from 'classnames';
import Popover from 'react-tiny-popover';
import './meta-popover.sass';

const MetaPopover = ({
  contentComponent,
  position,
  iconClass,
  size,
  useOnClick,
  multiLine,
  containerClass,
  iconComponent,
}) => {
  const [isPopoverVisible, setPopoverVisible] = useState(false);

  return (
    <Popover
      isOpen={isPopoverVisible}
      position={position || 'right'}
      containerClassName={containerClass || 'popover-body'}
      onClickOutside={() => setPopoverVisible(false)}
      align="start"
      content={(
        <div
          tabIndex={0}
          role="button"
          className={ClassNames('meta-popover', size, { 'multi-line': multiLine })}
          onClick={e => {
            if (useOnClick) {
              e.stopPropagation();
              setPopoverVisible(false);
            }
          }
          }
        >
          {contentComponent}
        </div>
      )}
    >
      <div
        tabIndex={0}
        role="button"
        className={ClassNames(isPopoverVisible ? 'active' : '', 'show-popup-btn', iconClass)}
        onMouseEnter={() => (useOnClick ? null : setPopoverVisible(true))}
        onMouseLeave={() => (useOnClick ? null : setPopoverVisible(false))}
        onClick={e => {
          if (useOnClick) {
            e.preventDefault();
            e.stopPropagation();
            setPopoverVisible(true);
          }
        }
        }
      >
        {iconComponent || null}
      </div>
    </Popover>
  );
};

export default MetaPopover;
