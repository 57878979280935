import React from 'react';
import PropTypes from 'prop-types';
import Tooltip from '@material-ui/core/Tooltip';
import { makeStyles } from '@material-ui/core/styles';

const MetaToolTip = props => {
  const {
    text,
    placement,
    children,
    showArrow,
    enterDelay,
    style = {},
    interactive,
  } = props;
  const useStylesBootstrap = makeStyles(() => ({
    arrow: {
      color: '#263238',
    },
    tooltip: {
      fontFamily: 'Roboto-Regular',
      fontSize: '13px',
      backgroundColor: style.tooltip?.backgroundColor || '#263238',
      borderRadius: '6px',
      padding: '7px 12px',
      letterSpacing: 0,
      whiteSpace: 'pre-wrap',
      border: '1px solid #263238',
      color: style.tooltip?.color || 'white',
      maxHeight: '300px',
      overflowX: 'auto',
      maxWidth: style.tooltip?.maxWidth,
    },
  }));
  const classes = useStylesBootstrap();

  return (text ? (
    <Tooltip
      title={text}
      placement={placement}
      arrow={showArrow}
      enterDelay={enterDelay}
      classes={classes}
      interactive={interactive}
    >
      {children}
    </Tooltip>
  ) : (
    <div className={props.className}>{children}</div>
  ));
};

MetaToolTip.propTypes = {
  text: PropTypes.string,
  placement: PropTypes.string,
};

MetaToolTip.defaultProps = {
  text: undefined,
  placement: 'top',
};

export default MetaToolTip;
