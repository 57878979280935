import { config } from 'common/config/config';
import mcAppsUtils from 'common/utils/mc-apps-utils';
import { parseOrgName } from 'common/utils/utils';
import Localization from 'assets/localization';

function generateMetaConnectDirectLink(easylink = '') {
  const hostNames = window.location.hostname.split('.');
  const orgShortName = hostNames.length > 1 ? parseOrgName(hostNames[0]) : '';
  const directLink = mcAppsUtils.getSiteSuffix(config.metaConnectDirectLinkTemplate);
  if (easylink) {
    return Localization.formatString(`${directLink}${window.location.search}`, orgShortName);
  }
  return Localization.formatString(`${directLink}?org={0}&easylink=`, orgShortName);
}

function getEasyLinkHref(directLinkWithoutID, easylink, isSupportMode) {
  if (!easylink) {
    return '';
  }
  if (easylink.get('proxy')?.get('isolation')) {
    return `${window.location.origin}/mc/?easylink=${easylink.get('id')}`;
  }
  if (easylink.get('accessType') === 'native' && !isSupportMode) {
    if (!easylink.get('redirect')) {
      return `//${easylink.get('accessFqdn')}`;
    }
    const nativeLinkNoId = mcAppsUtils.generateNativeDirectLink();
    const searchQuery = window.location.search;
    return `${nativeLinkNoId}${searchQuery}`;
  }
  return `${directLinkWithoutID}${easylink.get('id')}${isSupportMode ? '&tickle=true' : ''}`;
}

export { generateMetaConnectDirectLink, getEasyLinkHref };
