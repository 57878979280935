import React from 'react';
import classNames from 'classnames';
import map from 'lodash/map';
import './timezone-selector.sass';
import { TIMEZONE_SETTINGS_ENUM } from '../../consts/consts';
import Localization from '../../assets/localization';

const TimezoneSelector = props => {
  const { selectedKey } = props;
  const createOptionRow = item => {
    const isSelected = item.key === selectedKey;
    return (
      <div
        role="button"
        tabIndex="0"
        onClick={() => props.onClick(item)}
        key={item.key}
        className={classNames('timezone-option', { active: isSelected })}
      >
        <div className="label">
          {item.label}
        </div>
        <div className={classNames('icon-active', { hide: !isSelected })} />
      </div>
    );
  };
  return (
    <div className="timezone-selector">
      <div className="text">
        {Localization.TIMEZONE_TOGGLE.LABEL}
      </div>
      {map(TIMEZONE_SETTINGS_ENUM, createOptionRow)}
    </div>
  );
};

export default TimezoneSelector;
