import React from 'react';
import './multi-select-checkbox.sass';
import MetaSelect from '../meta-select';

const MultiSelectCheckbox = props => {
  const {
    defaultValue = '',
    options = [],
    onChange = () => {},
    selectValue,
  } = props;
  return (
    <MetaSelect
      className="protocol-select-container"
      isMulti
      defaultValue={defaultValue}
      options={options}
      placeholder={props.placeholder}
      onChange={e => onChange(e)}
      value={selectValue}
    />
  );
};

export default MultiSelectCheckbox;
