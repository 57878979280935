import { createStore, compose, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import updateApiTokens from './api-auth-injector';

// import promise from 'redux-promise';

import Reducer from './reducer';

const enhancers = compose(
  applyMiddleware(updateApiTokens, thunk),
  window.devToolsExtension ? window.devToolsExtension() : f => f,
);

export default createStore(Reducer, enhancers);
