import React, { Component } from 'react';
import PropTypes from 'prop-types';
import debounce from 'lodash/debounce';
import has from 'lodash/has';
import classNames from 'classnames';
import IconButton from '@proofpoint/fuse-ui/components/IconButton';
import SearchHelpDropDown from './search-help-drop-down';
import MetaTextInput from '../meta-text-input';
import './meta-search-input.sass';

class MetaSearchInput extends Component {
  state = {
    filterText: '',
    focused: false,
  };

  componentDidMount() {
    this.debouncedHandleChangeFromProps = debounce(this.props.handleChange, 200);
    if (this.props.filterQuery) {
      this.setState({ filterText: this.props.filterQuery });
      if (this.props.localFilter) {
        this.props.handleChange(this.props.filterQuery);
      }
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.filterQuery !== this.props.filterQuery) {
      // eslint-disable-next-line no-unused-expressions, react/no-did-update-set-state
      (this.props.filterQuery || this.props.filterQuery === '') && this.setState({ filterText: this.props.filterQuery });
    }
  }

  onBlur = () => {
    this.setInputFocused(false);
  };

  onFocused = () => {
    if (!this.props.noFocusStyle) {
      this.setInputFocused(true);
    }
  };

  setInputFocused = focused => {
    this.setState({ focused });
  };


  handleClearButtonClick = () => {
    this.debouncedHandleChangeFromProps('');
    this.setState({ filterText: '' });
  };

  searchChange = value => {
    this.debouncedHandleChangeFromProps(value);
    this.setState({ filterText: value });
  };

  render() {
    const { resultsNavigation } = this.props;
    return (
      // eslint-disable-next-line jsx-a11y/no-static-element-interactions
      <div
        className={classNames(
          'meta-search-input',
          { 'search-filter-border': this.props.isSearchFilter },
          { focused: this.state.focused },
        )}
        onClick={this.props.onClick}
      >
        <MetaTextInput
          automationID={this.props.automationID}
          onFocus={this.onFocused}
          onBlur={this.onBlur}
          type="text"
          isSearch
          size={this.props.size}
          placeholder={this.props.placeholder}
          onChange={this.searchChange}
          value={this.state.filterText}
          disabled={this.props.disabled}
          /* eslint-disable-next-line jsx-a11y/no-autofocus */
          autoFocus={this.props.autoFocus}
          onKeyDown={this.props.onKeyDown}
          isValid={this.props.isValid}
          errorMsg={this.props.errorMsg}
          noErrorPlaceholder={!has(this.props, 'isValid') && !has(this.props, 'errorMsg')}
          checkValidationOnChange={this.props.checkValidationOnChange}
          validateOnInit={this.props.validateOnInit}
        />
        {resultsNavigation && (
          <div className="results-navigation">
            <div className="results-counters">
              <span>{resultsNavigation.markedResults}</span>
              <span>/</span>
              <span>{resultsNavigation.totalResults}</span>
            </div>
            <IconButton onClick={resultsNavigation.onNextResultClick} size="small">
              <div className="icon-chevron-down" />
            </IconButton>
            <IconButton onClick={resultsNavigation.onPrevResultClick} size="small">
              <div className="icon-chevron-down top" />
            </IconButton>
          </div>
        )}
        {(this.props.bulletsForDropdown || this.props.textForDropdown) && (
          <SearchHelpDropDown
            bullets={this.props.bulletsForDropdown}
            text={this.props.textForDropdown}
          />
        )}
      </div>
    );
  }
}

MetaSearchInput.propTypes = {
  handleChange: PropTypes.func,
  placeholder: PropTypes.string,
  filterQuery: PropTypes.string,
  disabled: PropTypes.bool,
};

MetaSearchInput.defaultProps = {
  handleChange: () => {},
  placeholder: '',
  filterQuery: '',
  disabled: false,
};

export default MetaSearchInput;
