import React from 'react';
import MetaAppsCategoryItem from '../meta-apps-category-item';
import Localization from '../../assets/localization';
import './meta-apps-category-view.sass';

const MetaAppsCategoryView = props => {
  const {
    collection,
    updateFunction,
    openInNewWindow,
    lastConnectionsCollection,
    ItemIconComponent,
    diagnosticMode,
    isSublabel,
  } = props;
  if (!collection || !collection.size) {
    return (
      <div className="meta-apps-category-view">
        <div className="no-items">{Localization.NO_ITEMS}</div>
      </div>
    );
  }
  return (
    <div className="meta-apps-category-view">
      {lastConnectionsCollection?.size > 0
      && (
        <div className="apps-section">
          <div className="apps-section-title">{Localization.LAST_CONNECTIONS.TITLE}</div>
          <div className="apps-grid">
            {lastConnectionsCollection.map(item => (
              <MetaAppsCategoryItem
                key={`section-item-${item.get('id')}`}
                label={item.get('name')}
                id={item.get('id')}
                item={item}
                protocol={item.get('protocol')}
                updateFunction={updateFunction}
                openInNewWindow={openInNewWindow}
                ItemIconComponent={ItemIconComponent}
                diagnosticMode={diagnosticMode}
                isSublabel={isSublabel}
              />
            ))}
          </div>
        </div>
      )
      }
      <div className="apps-section">
        <div className="apps-section-title">{Localization.MY_APPLICATIONS}</div>
        <div className="apps-grid">
          {collection.map(item => (
            <MetaAppsCategoryItem
              key={`section-item-${item.get('id')}`}
              label={item.get('name')}
              id={item.get('id')}
              item={item}
              updateFunction={updateFunction}
              openInNewWindow={openInNewWindow}
              ItemIconComponent={ItemIconComponent}
              diagnosticMode={diagnosticMode}
              isSublabel={isSublabel}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default MetaAppsCategoryView;
