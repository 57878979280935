import LocalizedStrings from 'react-localization';
import en from './translations.en';
import it from './translations.it';

const strings = new LocalizedStrings({
  en,
  it,
});

export default strings;
