import React from 'react';
import InputAdornment from '@material-ui/core/InputAdornment';

import classNames from 'classnames';

const AdornmentComponent = ({
  iconClass,
  onAddFunc,
  suffix,
  isSearch,
  value,
  isPassword,
  type,
  onHidePassword,
  onClearSearch,
}) => {
  const onClick = onAddFunc || onHidePassword || onClearSearch || (() => {});

  const getIconSearchValue = () => {
    if (isSearch) {
      return value ? 'icon-ic-search-x' : 'icon-search';
    }
    return '';
  };
  const getPasswordIcon = () => {
    if (isPassword) {
      return type === 'password' ? 'icon-hide-password' : 'icon-show-password';
    }
    return '';
  };

  const adornmentClass = classNames(iconClass,
    getPasswordIcon(),
    getIconSearchValue(), {
      'icon-add': onAddFunc,
      suffix,
      clickable: isSearch || isPassword || onAddFunc,
    });

  return adornmentClass
    ? (
      <InputAdornment position="start">
        <div
          tabIndex="0"
          role="button"
          className={adornmentClass}
          onClick={onClick}
        >
          {suffix}
        </div>
      </InputAdornment>
    ) : null;
};

export default AdornmentComponent;
