import React from 'react';
import classNames from 'classnames';
import Localization from '../../assets/localization';
import './meta-apps-table-component.sass';

const MetaAppsTableComponent = props => {
  const { collection, columns } = props;

  if (!collection || !collection.size) {
    return <div className={classNames('no-items')}>No items to display</div>;
  }
  return (
    <div className="meta-apps-table-component">
      <div className="table-title">{Localization.MY_APPLICATIONS}</div>
      <table>
        <thead>
          <tr>
            {columns.map(c => (
              <td key={c.get('name')}>
                {c.get('name')}
              </td>
            ))}
          </tr>
        </thead>
        <tbody>
          {collection.map(n => (
            <tr key={n.get('id')}>
              {columns.map(c => (
                <td key={c.get('key')}>
                  {c.get('display')
                    ? c.get('display').call(null, n.get(c.get('key')), n, collection)
                    : n.get(c.get('key'))}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default MetaAppsTableComponent;
