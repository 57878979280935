export const ROUTES = {
  META_CONNECT: '/mc/',
  APPS: '/apps',
  HOME: '/mc/home',
  SSP: '/ssp',
  PORTAL: '/portal',
  ERROR_PAGE: '/login/error',
};

export default {
  ROUTES,
};
