import React, { Children } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import './meta-spinner.sass';

function MetaSpinner({ spinning, width, height, inline, children, className }) {
  const wrapperStyle = Object.assign(
    {},
    width && { width },
    height && { height },
    inline && { display: 'inline-block' }
  );
  const spinnerStyle = {
    textAlign: 'center',
  };

  return spinning ? (
    <div className={classNames('spinner-wrapper', className)} style={wrapperStyle}>
      <span className={classNames('children', { hidden: spinning })}>{children}</span>
      <div className="meta-spinner" style={spinnerStyle}>
        <div />
        <div />
        <div />
      </div>
    </div>
  ) : (
    Children.only(children)
  );
}

MetaSpinner.propType = {
  spinning: PropTypes.bool,
  inline: PropTypes.bool,
  width: PropTypes.Number,
  height: PropTypes.Number,
};

MetaSpinner.defaultProps = {
  spinning: false,
};

export default MetaSpinner;
