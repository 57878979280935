import axios from 'axios';
import { config } from 'common/config/config';
import { RECENTLY_USED_APPS_AMOUNT } from 'common/consts/consts';
import { getEnvSuffix, parseApiEnv, isOrganizationInternal } from 'common/utils/utils';
import { COLLECTION_VIEW_TYPES } from 'consts/general-consts';

// Action Creators and Consts

export const ROUTES = {
  AUTHORIZE: '/login/authorize',
  GET_INFO: '/users/me',
  OAUTH_INFO: '/oauth/info',
  GET_FEATURE_FLAGS: '/feature_flags',
  MY_EASYLINKS: '/easylinks/me',
  GET_ORG_INFO: '/orgs/me',
  LOGIN: '/oauth/token',
  GRAPHQL: '/graphql',
  EASYLINKS: '/easylinks',
};

export const ACTION_NAMES = {
  // Check Token
  CHECK_TOKEN_FAIL: 'CHECK_TOKEN_FAIL',
  CHECK_TOKEN_START: 'CHECK_TOKEN_START',
  CHECK_TOKEN_SUCCESS: 'CHECK_TOKEN_SUCCESS',
  UPDATE_API_TOKENS: 'UPDATE_API_TOKENS',

  GET_USER_INFO_SUCCESS: 'GET_USER_INFO_SUCCESS',
  GET_PRIVILEGES_SUCCESS: 'GET_PRIVILEGES_SUCCESS',
  GET_FEATURE_FLAGS_SUCCESS: 'GET_FEATURE_FLAGS_SUCCESS',
  APP_LOAD_FINISHED: 'APP_LOAD_FINISHED',

  //  Entities
  GET_ENTITIES_FAIL: 'GET_ENTITIES_FAIL',
  GET_ENTITIES_START: 'GET_ENTITIES_START',
  GET_ENTITIES_SUCCESS: 'GET_ENTITIES_SUCCESS',
  UPDATE_LAST_CONNECTIONS: 'UPDATE_LAST_CONNECTIONS',

  //  Do API
  DO_API_START: 'DO_API_START',
  DO_API_SUCCESS: 'DO_API_SUCCESS',
  DO_API_FAIL: 'DO_API_FAIL',

  //  UI
  CHANGE_CATEGORY_VIEW: 'CHANGE_CATEGORY_VIEW',
  CHANGE_LINK_OPEN_METHOD: 'CHANGE_LINK_OPEN_METHOD',
  HIDE_TOAST: 'HIDE_TOAST',
  LIST_QUERY_SEARCH_CHANGE: 'LIST_QUERY_SEARCH_CHANGE',
  LIST_QUERY_FILTER_CHANGE: 'LIST_QUERY_FILTER_CHANGE',
  SHOW_TOAST: 'SHOW_TOAST',
};

const LAST_CONNECTIONS_STORAGE = 'lastConnections';
const MC_VIEW_MODE = 'metaconnectViewMode';
const LINK_OPEN_MODE = 'metaconnectlinkOpenMode';
const DEFAULT_EASYLINK_VIEW = COLLECTION_VIEW_TYPES.get('TILES');
const DEFAULT_LINK_OPEN_MODE = true; // Open in new window

function loginWithCode(code) {
  const baseUrl = config.server;
  const route = ROUTES.LOGIN;
  const reqData = {
    grant_type: 'authorization_code',
    client_id: 'portal',
    code,
    scope: `org:${config.eorg_short_name || config.org_short_name}`,
  };
  return axios.post(`${baseUrl}${route}`, reqData);
}

function getUserInfo() {
  const baseUrl = config.server;
  const route = ROUTES.GET_INFO;
  return axios.get(`${baseUrl}${route}`);
}

function getOAuthInfo() {
  const baseUrl = config.server;
  const route = ROUTES.OAUTH_INFO;
  return axios.get(`${baseUrl}${route}`);
}

function getFeatureFlags() {
  const baseUrl = config.server;
  const route = ROUTES.GET_FEATURE_FLAGS;
  return axios.get(`${baseUrl}${route}`);
}

function getUserOrgInfo() {
  const baseUrl = config.server;
  const route = ROUTES.GET_ORG_INFO;
  return axios.get(`${baseUrl}${route}?expand=true`);
}

function getUserAndOrgInfo() {
  return new Promise((resolve, reject) => {
    Promise.all([getUserOrgInfo(), getUserInfo(), getOAuthInfo(), getFeatureFlags()])
      .then((res) => {
        resolve({
          data: {
            effective_org: res[0].data,
            tenantType: isOrganizationInternal(res[0].data),
            user: res[1].data,
            privileges: res[2].data.privileges,
            ztnaLicense: res[2].data.ztna_license,
            wsLicense: res[2].data.proxy_license,
            featureFlags: res[3].data,
          },
        });
      })
      .catch(err => reject(err));
  });
}

function getNewToken(refreshToken) {
  const baseUrl = config.server;
  const route = ROUTES.LOGIN;
  const reqData = {
    grant_type: 'refresh_token',
    refresh_token: refreshToken,
    scope: `org:${config.eorg_short_name || config.org_short_name}`,
  };
  return axios.post(`${baseUrl}${route}`, reqData);
}

function getIsolationURL(id) {
  const baseUrl = config.server;
  const route = `${ROUTES.EASYLINKS}/${id}/isolate`;
  return axios.post(`${baseUrl}${route}`);
}

function redirectToOrgLogin(orgShotName) {
  const query = window.location.search || '';
  //  Resolve a case where trying to redirect to unknown login org after successful login
  const fixedQuery = query.replace('https%3A%2F%2Flogin', `https%3A%2F%2F${orgShotName}`);
  const apiEnv = parseApiEnv(false, false);
  const envSuffix = getEnvSuffix();
  const loginUrl = `${envSuffix}.${config.metaSite}/login/`;
  window.location.href = `https://${apiEnv}${orgShotName}${loginUrl}${fixedQuery}`;
}

function updateTokens(tokenData = {}) {
  return () => {
    axios.defaults.headers.common.Authorization = `Bearer ${tokenData.accessToken}`;
  };
}

function getMyEasyLinks(options = {}) {
  const baseUrl = config.server;
  const route = ROUTES.MY_EASYLINKS;
  let axiosConfig = null;
  if (options.accessToken) {
    axiosConfig = { headers: { Authorization: `Bearer ${options.accessToken}` } };
  }
  return axios.get(`${baseUrl}${route}?expand=true`, axiosConfig);
}

function makeGqlRequest(query, variables) {
  const baseUrl = config.server;
  const route = ROUTES.GRAPHQL;
  const reqData = {
    query,
    variables,
  };
  return axios.post(`${baseUrl}${route}`, reqData);
}

function getLastConnections() {
  const lastConnections = localStorage.getItem(LAST_CONNECTIONS_STORAGE) || '[]';
  let recentConnections;
  try {
    recentConnections = JSON.parse(lastConnections);
  } catch (e) {
    recentConnections = [];
  }
  return recentConnections;
}

function addLastConnection(id) {
  const currentLastConnections = getLastConnections();
  const existingConnectionLocation = currentLastConnections
    .findIndex(connection => connection.id === id);
  if (existingConnectionLocation > -1) {
    currentLastConnections.splice(existingConnectionLocation, 1);
  }
  currentLastConnections.push({ id, lastAccess: Date.now() });
  if (currentLastConnections.length > RECENTLY_USED_APPS_AMOUNT) {
    currentLastConnections.shift();
  }
  localStorage.setItem(LAST_CONNECTIONS_STORAGE, JSON.stringify(currentLastConnections));
  return getLastConnections();
}

function updateLastConnections(id) {
  return (dispatch) => {
    let lastConnections;
    if (id) {
      lastConnections = addLastConnection(id);
    } else {
      lastConnections = getLastConnections();
    }
    dispatch({ type: ACTION_NAMES.UPDATE_LAST_CONNECTIONS, payload: { lastConnections } });
  };
}

function getEasyLinksDefaultView() {
  return localStorage.getItem(MC_VIEW_MODE) || DEFAULT_EASYLINK_VIEW;
}

function getLinkOpenDefaultMode() {
  const methodFromStorage = localStorage.getItem(LINK_OPEN_MODE);
  return methodFromStorage ? methodFromStorage === 'true' : DEFAULT_LINK_OPEN_MODE;
}

function switchEasyLinksView(view) {
  return (dispatch) => {
    localStorage.setItem(MC_VIEW_MODE, view);
    dispatch({ type: ACTION_NAMES.CHANGE_CATEGORY_VIEW, payload: view });
  };
}

function switchLinkOpenMethod(value) {
  return (dispatch) => {
    localStorage.setItem(LINK_OPEN_MODE, value);
    dispatch({ type: ACTION_NAMES.CHANGE_LINK_OPEN_METHOD, payload: value });
  };
}

function onSearchList(value) {
  return (dispatch) => {
    dispatch({ type: ACTION_NAMES.LIST_QUERY_SEARCH_CHANGE, payload: value });
  };
}

function onCategoryFilterChange(value) {
  return (dispatch) => {
    dispatch({ type: ACTION_NAMES.LIST_QUERY_FILTER_CHANGE, payload: value });
  };
}

function makeGqlQuery(query, variables) {
  return (dispatch, getState) => {
    dispatch({ type: ACTION_NAMES.GET_ENTITIES_START });
    const { featureFlags } = getState().AppState;
    makeGqlRequest(query, variables)
      .then((response) => {
        dispatch({ type: ACTION_NAMES.GET_ENTITIES_SUCCESS, payload: response, featureFlags });
      })
      .catch((error = {}) => {
        dispatch({ type: ACTION_NAMES.GET_ENTITIES_FAIL, payload: error });
      });
  };
}

export {
  getEasyLinksDefaultView,
  getMyEasyLinks,
  getLastConnections,
  getLinkOpenDefaultMode,
  getNewToken,
  getIsolationURL,
  getUserInfo,
  getOAuthInfo,
  getFeatureFlags,
  getUserOrgInfo,
  getUserAndOrgInfo,
  loginWithCode,
  makeGqlQuery,
  onSearchList,
  onCategoryFilterChange,
  redirectToOrgLogin,
  switchEasyLinksView,
  switchLinkOpenMethod,
  updateLastConnections,
  updateTokens,
};
