import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { fromJS } from 'immutable';
import { Switch, Route, withRouter, Redirect } from 'react-router-dom';
import qs from 'query-string';
import ClassNames from 'classnames';
import { ThemeProvider } from '@material-ui/core/styles';
import { fuseTheme } from '@proofpoint/fuse-ui';
import MetaAppsFilterBar from 'common/ui/meta-apps-filter-bar';
import { config } from 'common/config/config';
import { getWhiteLabelAsset, checkImage, goToRoute } from 'common/utils/utils';
import MetaHeader from 'common/ui/meta-header';
import { getAppSwitcherItems } from 'common/ui/meta-header/meta-header-utils';
import { UI_APPS } from 'common/consts/routes';
import { startLogoutWatchInterval, logout } from 'common/actions/actions';
import Localization from 'assets/localization';
import { ROUTES } from 'consts/routes';
import Toast from 'components/toast';
import EasyLinkIcon from 'components/easylink-icon';
import IdleTimerWrapper from 'components/idle-timer-wrapper/idle-timer-wrapper';
import HomePage from 'scenes/home/home';
import { loadUserFromToken } from 'scenes/app/app-state';
import './app.sass';
import {
  onCategoryFilterChange,
  onSearchList,
  switchEasyLinksView,
  switchLinkOpenMethod,
} from '../../actions/actions';
import { PROTOCOL_TYPES, PROTOCOL_OPTIONS } from '../../consts/general-consts';

const wrapperStyle = whiteLabelBg => (whiteLabelBg ? { backgroundImage: `url(${whiteLabelBg})` } : {});

class App extends React.Component {
  state = {};

  componentDidMount() {
    const { location, history, loadUser } = this.props;
    const parsedQueryParams = qs.parse(window.location.search);
    const { code, easylink, tickle } = parsedQueryParams;

    const backgroundPath = getWhiteLabelAsset('metaconnect_background');
    checkImage(
      backgroundPath,
      () => this.setState({ whiteLabelBg: backgroundPath }),
      () => {},
    );

    delete parsedQueryParams.code;
    history.push({
      ...location,
      search: qs.stringify(parsedQueryParams),
    });
    loadUser(code, easylink, tickle);
    startLogoutWatchInterval(logout);
  }

  goToNoMCErrorPage = (orgInfo) => {
    const errorReason = Localization.formatString(
      Localization.NO_MC_ERROR,
      orgInfo.name || config.org_short_name,
    );
    goToRoute(ROUTES.ERROR_PAGE, {
      title: Localization.NO_MC_ERROR_TITLE,
      reason: errorReason,
    });
  };

  render() {
    const {
      loading,
      userLoaded,
      myInfo,
      orgInfo,
      filterBar = {},
      data = {},
      toggleEasyLinksView,
      toggleLinkOpenMethod,
      filterBarSearchChange,
      filterBarFilterChange,
      featureFlags,
      privileges,
      ztnaLicense,
      wsLicense,
    } = this.props;
    const { easyLinks = [] } = data;
    const collection = fromJS(easyLinks).map((easyLink) => {
      const protocol = easyLink.get('protocol');
      return easyLink.set(
        'type',
        protocol === PROTOCOL_TYPES.HTTP || protocol === PROTOCOL_TYPES.HTTPS
          ? 'web'
          : protocol,
      );
    });

    const { whiteLabelBg } = this.state;
    if (loading || !userLoaded) {
      return <div />;
    }

    if (
      myInfo
      && myInfo.inventory.findIndex(device => device.startsWith('mc-')) === -1
    ) {
      this.goToNoMCErrorPage(orgInfo);
      return <div />;
    }

    const appSwitcherItems = getAppSwitcherItems(
      featureFlags,
      privileges,
      ztnaLicense,
      wsLicense,
    );

    return (
      <div className="meta-mc">
        <IdleTimerWrapper />
        <ThemeProvider theme={fuseTheme}>
          <div>
            <Toast />
            <MetaHeader
              appName={Localization.APP_TITLE}
              appSwitcher={appSwitcherItems}
              orgName={orgInfo.name}
              userData={{
                userInfo: myInfo && { name: myInfo.name, email: myInfo.email },
                logOutFunc: logout,
                myInfoFunc: () => {},
                userCanSeeMyInfoPage: false,
              }}
              appSwitcherCurrentApp={UI_APPS.MC}
            />
            <MetaAppsFilterBar
              count={collection.size}
              onViewToggle={toggleEasyLinksView}
              currentView={filterBar.currentView}
              openLinkInNewWindow={filterBar.openLinkInNewWindow}
              onOpenMethodToggle={toggleLinkOpenMethod}
              onFilterSearchChanged={filterBarSearchChange}
              onFilterFilteringChanged={filterBarFilterChange}
              protocolOptions={PROTOCOL_OPTIONS}
              ProtocolIconComponent={EasyLinkIcon}
              selectValue={filterBar.queryType}
            />
            <div
              className={ClassNames(
                'app-content-wrapper',
                !whiteLabelBg ? 'meta-theme' : '',
              )}
              style={wrapperStyle(whiteLabelBg)}
            >
              <Switch>
                <Route path={ROUTES.HOME} render={() => <HomePage />} />
                <Route
                  path="*"
                  render={() => (
                    <Redirect
                      to={{
                        pathname: ROUTES.HOME,
                        search: window.location.search,
                      }}
                    />
                  )}
                />
              </Switch>
            </div>
          </div>
        </ThemeProvider>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    ...state.AppState,
    ...state.UIState,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      loadUser: loadUserFromToken,
      toggleEasyLinksView: switchEasyLinksView,
      toggleLinkOpenMethod: switchLinkOpenMethod,
      filterBarFilterChange: onCategoryFilterChange,
      filterBarSearchChange: onSearchList,
    },
    dispatch,
  );
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(App));
