import React from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';
import { createRoot } from 'react-dom/client';
import { getUrlParameter, updateOrgsInConfig } from 'common/utils/utils';
import { initConfig } from 'common/config/config';
import App from 'scenes/app/app';
import Store from './redux/store';
import './styles/main.sass';
import 'normalize.css';

if (module.hot) module.hot.accept();

const WrapperContainer = (props) => {
  const { appClientName } = props;
  return (
    <div className={appClientName}>
      <Switch>
        <Route path="/mc" component={App} />
        <Route
          path="*"
          render={() => (
            <Redirect
              to={{
                pathname: '/mc',
                search: window.location.search,
              }}
            />
          )}
        />
      </Switch>
    </div>
  );
};

const AppContainer = (props) => {
  const { appClientName } = props;
  return (
    <Provider store={Store}>
      <Router>
        <WrapperContainer appClientName={appClientName} />
      </Router>
    </Provider>
  );
};

function renderApp() {
  updateOrgsInConfig();
  const appClientName = getUrlParameter('app') || '';
  const container = document.getElementById('root');
  const root = createRoot(container);
  root.render(<AppContainer appClientName={appClientName} />);
}

(function init() {
  initConfig();
  renderApp();
}());

// Prevents firefox from caching the page data
window.onunload = function unloadMe() {};
