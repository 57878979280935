import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ClickOutside from 'react-click-outside';
import classNames from 'classnames';
import './search-help-drop-down.sass';

const Bullet = ({ content }) => (
  <li>
    <span className="bold-text">
      {content.title}
      :
      {' '}
    </span>
    {content.text}
  </li>
);

class SearchHelpDropDown extends Component {
  state = {
    toggled: false,
  };

  onToggleHelpClick = () => {
    this.setState(prevState => ({ toggled: !prevState.toggled }));
  };

  render() {
    const { toggled } = this.state;
    return (
      <ClickOutside className="help-wrapper" onClickOutside={() => this.setState({ toggled: false })}>
        <div className="search-help-drop-down">
          <div className="toggle-help-btn-container">
            <button
              type="button"
              className={`toggle-help-btn${toggled ? ' toggled' : ''}`}
              onClick={this.onToggleHelpClick}
            >
              <div className="icon-tooltip" />
            </button>
          </div>
          <div className={classNames('help-content', { toggled })}>
            {this.props.title && <div className="search-help-title">{this.props.title}</div>}
            {this.props.bullets.length > 0 && (
              <ul>
                {this.props.bullets.map((bullet, index) => (
                  // eslint-disable-next-line react/no-array-index-key
                  <Bullet content={bullet} key={`bullet-${index}`} />
                ))}
              </ul>
            )}
            {this.props.text && (
              <div className="search-help-text">{this.props.text}</div>
            )}
          </div>
        </div>
      </ClickOutside>
    );
  }
}

export default SearchHelpDropDown;

SearchHelpDropDown.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  bullets: PropTypes.array,
};

SearchHelpDropDown.defaultProps = {
  bullets: [],
};
