import React, { Fragment, useState, useRef, useImperativeHandle, useEffect, forwardRef } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import TextField from '@proofpoint/fuse-ui/components/TextField';
import './meta-text-input.sass';
import AdornmentComponent from './input-adornments';
import MetaCopyButton from '../meta-copy-button';

const TEXT_FIELD_TYPE = 'text';

const MetaTextInput = forwardRef((props, ref) => {
  const inputRef = useRef(null);
  const [value, setValue] = useState(props.value ?? '');
  const [isValid, setIsValid] = useState(props.validateOnInit ? props.isValid : true);

  useEffect(() => {
    if (props.checkValidationOnChange && props.isValid !== isValid) {
      setIsValid(props.isValid);
    }
  }, [props.isValid]);

  useEffect(() => {
    if (props.triggerIsValid) {
      setIsValid(props.isValid);
    }
  }, [props.triggerIsValid]);

  useEffect(() => {
    if (value !== props.value) {
      setValue(props.value ?? '');
      setIsValid(props.validateOnInit ? props.isValid : true);
    }
  }, [props.value, props.isValid]);

  const {
    placeholder,
    inputLabel,
    inputLabelProps = {},
    errorMsg,
    disabled,
    multiLine,
    rows,
    type,
    size,
    suffix,
    subtitle,
    onAddFunc,
    isPassword,
    autoComplete,
    isSearch,
    isMandatory,
    checkValidationOnChange,
    automationID = 'meta-input',
  } = props;

  useEffect(() => {
    if (props.disabled) {
      setIsValid(true);
    }
  }, [props.disabled]);

  const onChange = event => {
    if (checkValidationOnChange) {
      setIsValid(props.isValid);
    } else {
      setIsValid(true);
    }
    setValue(event.target.value);
    props.onChange(event.target.value);
  };

  const onFocus = () => {
    if (!props.externalValidation) {
      setIsValid(true);
      props.onFocus();
    }
  };

  const onBlur = () => {
    if (!props.externalValidation) {
      props.onBlur();
      setIsValid(props.isValid);
    }
  };

  useImperativeHandle(ref, () => ({
    resetInput() {
      setIsValid(props.validateOnInit ? props.isValid : true);
      setValue('');
    },
    focus() {
      inputRef.current.focus();
    },
    blur() {
      inputRef.current.blur();
    },
    changeValue(inputValue) {
      setValue(inputValue);
    },
  }));

  const getHelperTextContent = () => ((subtitle || errorMsg)
    ? (
      <Fragment>
        {subtitle && (
          <span>
            {subtitle}
          </span>
        )}
        {!isValid && (
          <span>
            {errorMsg}
          </span>
        )}
      </Fragment>
    ) : null);

  const helpTextProps = {
    helperText: getHelperTextContent(),
    error: Boolean(!isValid && errorMsg),
  };

  const currValue = (() => {
    if (value === '' && props.value !== '') {
      return props.value || '';
    }
    return value;
  })();

  const onClearSearch = () => currValue && onChange({ target: { value: '' } });

  const adornmentProps = {
    iconClass: props.iconClass,
    onAddFunc,
    suffix,
    isSearch,
    value: currValue,
    type,
    isPassword,
    onHidePassword: props.onHidePassword,
    onClearSearch: isSearch ? onClearSearch : undefined,
  };

  const inputProps = {
    ref: inputRef,
    placeholder: props.placeholder,
    value: currValue,
    onChange,
    onBlur,
    onFocus,
    onKeyPress: props.onKeyPress,
    type: props.type || TEXT_FIELD_TYPE,
    disabled: props.disabled,
    maxLength: props.maxLength,
    onClick: props.onClick,
    onKeyDown: props.onKeyDown,
    rows: props.rows || 3,
    className: props.className,
    autoFocus: props.autoFocus,
    min: props.minValue,
    max: props.maxValue,
    list: props.listId,
    'data-automation': automationID,
  };

  return (
    <div
      className={classNames(
        'meta-text-input',
        props.containerClassName,
        size,
        { search: isSearch,
          suffix,
          'read-only': props.readOnly,
          'error-placeholder': !props.noErrorPlaceholder && !props.multiLine,
        },
      )}
    >
      <TextField
        required={isMandatory}
        label={inputLabel}
        value={value}
        onChange={onChange}
        disabled={disabled}
        placeholder={placeholder}
        multiline={multiLine}
        InputProps={{
          endAdornment: <AdornmentComponent {...adornmentProps} />,
        }}
        /* eslint-disable */
        inputProps={inputProps}
        InputLabelProps={inputLabelProps}
        rows={rows}
        type={type}
        autoComplete={autoComplete}
        readOnly={props.readOnly}
        {...helpTextProps}
      />
      {props.inputSuffix && <div className={classNames('input-suffix', disabled ? 'disabled' : '')}>{props.inputSuffix}</div> }
      {props.showCopyButton && <MetaCopyButton isLean textToCopy={value} />}
      {props.datalist}
    </div>
  );
});

MetaTextInput.propTypes = {
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  autoFocus: PropTypes.bool,
  multiLine: PropTypes.bool,
};

MetaTextInput.defaultProps = {
  placeholder: '',
  onChange: () => {},
  onClick: () => {},
  onFocus: () => {},
  onBlur: () => {},
  disabled: false,
  autoFocus: false,
  multiLine: false,
};

export default MetaTextInput;
