import React, { useState } from 'react';
import ClickOutside from 'react-click-outside';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { executeOnEnterKey } from '../../../utils/utils';
import './meta-header-icon-menu.sass';

const MetaHeaderIconMenu = ({
  icon,
  text,
  menuItems,
  menuItemsWithRender,
  otherOrg,
}) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const iconOnClick = () => setIsMenuOpen(!isMenuOpen);

  const renderMenuItems = () => menuItems.map((item) => {
    const { label, onClickFunc } = item;
    return (
      <div
        tabIndex={0}
        role="button"
        key={label}
        className="menu-item"
        onClick={onClickFunc && onClickFunc}
        onKeyDown={e => executeOnEnterKey(e, onClickFunc)}
      >
        {label}
      </div>
    );
  });

  return (
    <div className="meta-header-icon-menu">
      <ClickOutside className="fill-height" onClickOutside={() => setIsMenuOpen(false)}>
        <div className="icon-container">
          <div
            tabIndex={0}
            role="button"
            className={classNames(
              'icon-background',
              { 'other-org-background': otherOrg },
            )}
            onClick={iconOnClick}
            onKeyDown={e => executeOnEnterKey(e, iconOnClick)}
          >
            {text && (
              <span className="icon-item">
                <span className={classNames({ 'other-org-color': otherOrg })}>{text}</span>
              </span>
            )}
            {icon && <span className={classNames(`apps-icon ${icon}`)} />}
          </div>
          {isMenuOpen
            && (menuItems || menuItemsWithRender) && (
            <div
              tabIndex={0}
              role="button"
              className="menu-body"
              onClick={() => setIsMenuOpen(false)}
              onKeyDown={e => executeOnEnterKey(e, setIsMenuOpen(false))}
            >
              {menuItems ? renderMenuItems() : menuItemsWithRender && menuItemsWithRender()}
            </div>
          )}
        </div>
      </ClickOutside>
    </div>
  );
};

MetaHeaderIconMenu.propTypes = {
  icon: PropTypes.string,
  text: PropTypes.string,
  menuItems: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string,
    onClickFunc: PropTypes.func,
  })),
  menuItemsWithRender: PropTypes.func,
  otherOrg: PropTypes.bool,
};

MetaHeaderIconMenu.defaultProps = {
  icon: null,
  text: null,
  menuItems: null,
  menuItemsWithRender: null,
  otherOrg: false,
};

export default MetaHeaderIconMenu;
