import React, { Component } from 'react';
import { logoutUser } from 'common/utils/utils';
import IdleTimer from 'react-idle-timer';

const MILLISECONDS_IN_MINUTE = 1000 * 60;
const IDLE_TIMER_IN_MINUTES = 15;
const IDLE_TIMER_IN_MILLISECONDS = MILLISECONDS_IN_MINUTE * IDLE_TIMER_IN_MINUTES;
const LocalStorageKey = 'metaIdleLastAction';

class IdleTimerWrapper extends Component {
  onIdle = () => {
    const lastActionTime = localStorage.getItem(LocalStorageKey) || 0;
    if (Date.now() - lastActionTime < IDLE_TIMER_IN_MILLISECONDS) {
      this.idleTimer.reset();
    } else {
      localStorage.removeItem(LocalStorageKey);
      logoutUser();
    }
  };

  onAction = () => {
    localStorage.setItem(LocalStorageKey, Date.now());
  };

  render() {
    return (
      <IdleTimer
        ref={(ref) => {
          this.idleTimer = ref;
        }}
        element={document}
        timeout={IDLE_TIMER_IN_MILLISECONDS}
        onIdle={this.onIdle}
        onAction={this.onAction}
        throttle={30000}
      />
    );
  }
}

export default IdleTimerWrapper;
